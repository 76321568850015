/* global PubSubHelper */
import { createContext, useContext, useState } from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Router from './Router';
import { THEME_DARK, THEME_LIGHT } from './App.constant';
import translationEN from '@locales/en/default.json';
import translationHi from '@locales/hi/default.json';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

export const store = configureStore();

export const PubSubContext = createContext({
  PubSubHelper,
});

export const ThemeContext = createContext();

const themes = {
  [THEME_LIGHT]: {
    rootBackground: '--surface-neutral',
    rootTextColor: '--primary-text',
  },
  [THEME_DARK]: {
    rootBackground: '--surface-black',
    rootTextColor: '--surface-neutral',
  },
};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    hi: { translation: translationHi },
  },
  lng: 'en', // if you're using a language detector, do not define the lng option
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const [theme, setTheme] = useState(THEME_LIGHT);

  const PubSubHelper = useContext(PubSubContext);

  return (
    <PubSubContext.Provider>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Provider store={store}>
          <div className="App" style={{ opacity: 1, background: `var(${themes[theme].rootBackground})`, color: `var(${themes[theme].rootTextColor})` }}>
            <Router PubSubHelper={PubSubHelper} />
          </div>
        </Provider>
      </ThemeContext.Provider>
    </PubSubContext.Provider>
  );
}

export default App;
