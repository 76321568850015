const intialFileUploadList = [];

// export function fileUploadList(state = intialFileUploadList, action) {
//     switch (action.type) {
//         case 'SET_FILE_UPLOAD_DATA':
//             const data = [...state, ...action.data];
//             return data;

//         case 'REMOVE_FILE_UPLOAD_DATA': {
//             const stateData = [...state];
//             stateData.splice(action.data, 1);
//             const result = stateData.map((record, index) => ({ ...record, index }));
//             return result;
//         }

//         case 'UPDATE_FILE_UPLOAD_DATA': {
//             const stateData = [...state];
//             if (stateData[action.data.index].isFolder && action.data.data.bytesUploaded) {
//                 stateData[action.data.index].totalBytesUploaded = (stateData[action.data.index].totalBytesUploaded ?? 0) + action.data.data.bytesUploaded;
//                 const percentageComplete = Math.floor((stateData[action.data.index].totalBytesUploaded * 100) / stateData[action.data.index].size);
//                 stateData[action.data.index].progress = percentageComplete;
//             }
//             stateData[action.data.index] = {
//                 ...stateData[action.data.index],
//                 ...action.data.data,
//             };
//             return stateData;
//         }

//         case 'DOCUMENT_CHANGE': {
//             const stateData = [...state];
//             const location = stateData[action.data.index].location;
//             let documentType = [action.data.data];
//             let isMetadataPending = false;

//             if (Array.isArray(stateData[action.data.index].documentType)) {
//                 documentType = [...stateData[action.data.index].documentType, action.data.data];
//             }

//             if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
//                 isMetadataPending = true;
//             }

//             stateData[action.data.index] = {
//                 ...stateData[action.data.index],
//                 documentType,
//                 isMetadataPending,
//             };
//             return stateData;
//         }

//         case 'DOCUMENT_REMOVE': {
//             const stateData = [...state];
//             const location = stateData[action.data.index].location;
//             const documentType = stateData[action.data.index].documentType.filter((data) => data != action.data.data);
//             let isMetadataPending = false;

//             if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
//                 isMetadataPending = true;
//             }

//             stateData[action.data.index] = {
//                 ...stateData[action.data.index],
//                 documentType,
//                 isMetadataPending,
//             };
//             return stateData;
//         }

//         case 'LOCATION_CHANGE': {
//             const stateData = [...state];
//             const documentType = stateData[action.data.index].documentType;
//             let location = [action.data.data];
//             let isMetadataPending = false;

//             if (Array.isArray(stateData[action.data.index].location)) {
//                 location = [...stateData[action.data.index].location, action.data.data];
//             }

//             if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
//                 isMetadataPending = true;
//             }

//             stateData[action.data.index] = {
//                 ...stateData[action.data.index],
//                 location,
//                 isMetadataPending,
//             };
//             return stateData;
//         }

//         case 'LOCATION_REMOVE': {
//             const stateData = [...state];
//             const location = stateData[action.data.index].location.filter((data) => data != action.data.data);
//             const documentType = stateData[action.data.index].documentType;
//             let isMetadataPending = false;

//             if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
//                 isMetadataPending = true;
//             }

//             stateData[action.data.index] = {
//                 ...stateData[action.data.index],
//                 location,
//                 isMetadataPending,
//             };
//             return stateData;
//         }

//         case 'EMPTY_FILE_UPLOAD_DATA':
//             return [];

//         default:
//             return state;
//     }
// }
export function fileUploadList(state = intialFileUploadList, action) {
    switch (action.type) {
      case 'SET_FILE_UPLOAD_DATA':
        return [...state, ...action.data];

      case 'REMOVE_FILE_UPLOAD_DATA':
        return removeFileUploadData(state, action);

      case 'UPDATE_FILE_UPLOAD_DATA':
        return updateFileUploadData(state, action);

      case 'DOCUMENT_CHANGE':
        return documentChange(state, action);

      case 'DOCUMENT_REMOVE':
        return documentRemove(state, action);

      case 'LOCATION_CHANGE':
        return locationChange(state, action);

      case 'LOCATION_REMOVE':
        return locationRemove(state, action);

      case 'EMPTY_FILE_UPLOAD_DATA':
        return [];

      default:
        return state;
    }
  }

  function removeFileUploadData(state, action) {
    const stateData = [...state];
    stateData.splice(action.data, 1);
    return stateData.map((record, index) => ({ ...record, index }));
  }

  function updateFileUploadData(state, action) {
    const stateData = [...state];
    const index = action.data.index;

    if (stateData[index].isFolder && action.data.data.bytesUploaded) {
      stateData[index].totalBytesUploaded = (stateData[index].totalBytesUploaded ?? 0) + action.data.data.bytesUploaded;
      const percentageComplete = Math.floor((stateData[index].totalBytesUploaded * 100) / stateData[index].size);
      stateData[index].progress = percentageComplete;
    }

    stateData[index] = {
      ...stateData[index],
      ...action.data.data,
    };
    return stateData;
  }

  function documentChange(state, action) {
    const stateData = [...state];
    const index = action.data.index;
    const location = stateData[index].location;
    let documentType = [action.data.data];
    let isMetadataPending = false;

    if (Array.isArray(stateData[index].documentType)) {
      documentType = [...stateData[index].documentType, action.data.data];
    }

    if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
      isMetadataPending = true;
    }

    stateData[index] = {
      ...stateData[index],
      documentType,
      isMetadataPending,
    };
    return stateData;
  }

  function documentRemove(state, action) {
    const stateData = [...state];
    const index = action.data.index;
    const location = stateData[index].location;
    const documentType = stateData[index].documentType.filter((data) => data !== action.data.data);
    let isMetadataPending = false;

    if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
      isMetadataPending = true;
    }

    stateData[index] = {
      ...stateData[index],
      documentType,
      isMetadataPending,
    };
    return stateData;
  }

  function locationChange(state, action) {
    const stateData = [...state];
    const index = action.data.index;
    const documentType = stateData[index].documentType;
    let location = [action.data.data];
    let isMetadataPending = false;

    if (Array.isArray(stateData[index].location)) {
      location = [...stateData[index].location, action.data.data];
    }

    if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
      isMetadataPending = true;
    }

    stateData[index] = {
      ...stateData[index],
      location,
      isMetadataPending,
    };
    return stateData;
  }

  function locationRemove(state, action) {
    const stateData = [...state];
    const index = action.data.index;
    const location = stateData[index].location.filter((data) => data !== action.data.data);
    const documentType = stateData[index].documentType;
    let isMetadataPending = false;

    if (!location || (Array.isArray(location) && !location.length) || !documentType || (Array.isArray(documentType) && !documentType.length)) {
      isMetadataPending = true;
    }

    stateData[index] = {
      ...stateData[index],
      location,
      isMetadataPending,
    };
    return stateData;
  }

export function fileUploadState(state = 'CLOSED', action) {
    switch (action.type) {
        case 'MINIMISED':
            return 'MINIMISED';

        case 'MAXIMISED':
            return 'MAXIMISED';

        case 'CLOSED':
            return 'CLOSED';

        default:
            return state;
    }
}

export function isFileVersionUploading(state = false, action) {
    switch (action.type) {
        case 'IS_FILE_VERSION_UPLOADING':
            return action.data;

        default:
            return state;
    }
}

export function fileVersionUploadState(state = 'CLOSED', action) {
    return fileUploadState(state, action);
}
