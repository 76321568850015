export function TaskData(state = [], action) {
  switch (action.type) {
    case 'SET_TASK_DATA':
      return action.data;

    case 'UPDATE_TASK_DATA':
      const tempState = [...state];
      const taskId = action.data.id;
      tempState[taskId].TASKS_STATUS.statusId = action.data.status;
      return tempState;

    case 'REMOVE_TASK_DATA':
      const localState = [...state];
      return localState;

    default:
      return state;
  }
}

export function TaskDetails(state = [], action) {
  switch (action.type) {
    case 'SET_TASK_DETAILS':
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}

export function GanttDetails(state = [], action) {
  switch (action.type) {
    case 'SET_SCHEDULE_DETAILS':
      return {
        ...action.data,
      };

    default:
      return state;
  }
}

export function TaskTypes(state = [], action) {
  switch (action.type) {
    case 'SET_TASK_TYPES':
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}

export function sliderTimer(state = [], action) {
  switch (action.type) {
    case 'SET_SCHEDULE_SLIDER_TIME':
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}

export function selectionSetTaskElements(state = [], action) {
  switch (action.type) {
    case 'SET_SELECTION_SET_TASK_ELEMENTS':
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
