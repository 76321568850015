import {
  AddIconComponentProps,
  AutoCompleteComponentProps,
  AvatarComponentProps,
  BorderStyleTypes,
  ButtonComponentProps,
  CardComponentProps,
  CheckboxComponentProps,
  ComboBoxComponentProps,
  DropdownButtonComponentProps,
  FeedbackToastComponentProps,
  IconComponentProps,
  MaskTypes,
  MultiSelectComponentProps,
  MultiSelectModesTypes,
  RichTextEditorComponentProps,
  SliderComponentProps,
  SplitButtonComponentProps,
  TextboxComponentProps,
  TextboxMaskComponentProps,
  TextboxNumericComponentProps,
  ToggleSwitchComponentProps,
  TooltipComponentProps,
  TableComponentProps,
  DragListComponentProps,
  SparklineGraphComponentProps,
  MapComponentProps
} from "../interface";
import { getStylePropertyList } from "./globalFunctions";
import { Filter, VirtualScroll, Sort } from "@syncfusion/ej2-react-grids";

export const COLORS = {
  WHITE: "white",
  BLACK: "black",
  DEFAULT_GRADIENT_1: "#6BBBE3",
  DEFAULT_GRADIENT_2: "#3E8AB0",
  BTN_GRADIENT_PRIMARY_1: "#6BBBE3",
  BTN_GRADIENT_PRIMARY_2: "#3E8AB0",
  BTN_COLOR_GRADIENT_PRIMARY_1: "#6BBBE3",
  BTN_COLOR_GRADIENT_PRIMARY_2: "#3E8AB0",
  DEFAULT_BG_COLOR: "#6BBBE3",
  BG_LIGHT_BLUE: "#ECF3FE",
  BTN_BG_DISABLED: "#ECF3FE",
  NATURAL_LIGHT_GRAY: "#EAEAEA",
  PRIMARY_TEXT_BLACK: "#192048",
  BTN_COLOR_DISABLED: "rgba(25, 32, 72, 0.5)",
  BTN_BORDER_DISABLED: "#F4F4F4",
  BTN_ACTIVE_BG_COLOR: "#45CF80",
  BTN_INPROGRESS_BG_COLOR: "#FFCF55",
  BTN_INACTIVE_BG_COLOR: "#E75555",
};

export const BUTTON_TYPES = {
  GRADIENT_BUTTON: "gradientButton",
  BACKGROUND_BUTTON: "backgroundButton",
  BORDER_BUTTON: "borderButton",
  TEXT_BUTTON: "textButton",
  ACTIVE_BUTTON: "activeButton",
  IN_ACTIVE_BUTTON: "inActiveButton",
  IN_PROGRESS_BUTTON: "inProgressButton",
  INFO_GRADIENT_BUTTON: "infoGradientButton",
  INFO_BACKGROUND_BUTTON: "infoBackgroundButton",
  SPLIT_GRADIENT_BUTTON: "splitGradientButton",
  SPLIT_BACKGROUND_BUTTON: "splitBackgroundButton",
  DROPDOWN_GRADIENT_BUTTON: "dropdownGradientButton",
  DROPDOWN_BACKGROUND_BUTTON: "dropdownBackgroundButton",
  DROPDOWN_GRADIENT_LIST: "dropdownGradientList",
  DROPDOWN_BACKGROUND_LIST: "dropdownBackgroundList",
  DROPDOWN_BACKGROUND_YELLOW_LIST: "dropdownBackgroundYellowList",
  DROPDOWN_BACKGROUND_GREEN_LIST: 'dropdownBackgroundGreenList',
  DROPDOWN_BACKGROUND_RED_LIST: 'dropdownBackgroundRedList',
  DROPDOWN_BACKGROUND_ROYAL_BLUE_LIST: 'dropdownBackgroundRoyalBlueList',
  DROPDOWN_BACKGROUND_TEXT_LIGHT_LIST: 'dropdownBackgroundTextLightList'
};

export const CONSTANTS = {
  NONE: "none",
  AUTO: "auto",
  BLANK: "",
  AVATAR_SIZE: ["SM", "XS", "LG", "XL"],
  BORDER_STYLE: [
    "double",
    "solid",
    "groove",
    "dotted",
    "dashed",
    "ridge",
    "inset",
  ],
  BORDER_WIDTH: getStylePropertyList({
    first: 1,
    last: 10,
    incBy: 1,
    postfix: "px",
  }),
  BORDER_RADIUS: getStylePropertyList({
    first: 1,
    last: 100,
    incBy: 1,
    postfix: "px",
  }),
  FONT_WEIGHT: getStylePropertyList({
    first: 100,
    last: 900,
    incBy: 100,
  }),
  FONT_SIZE: getStylePropertyList({
    first: 1,
    last: 100,
    incBy: 1,
    postfix: "px",
  }),
  TOOL_TIP_POSITION: [
    "TopLeft",
    "TopCenter",
    "TopRight",
    "BottomLeft",
    "BottomCenter",
    "BottomRight",
    "LeftTop",
    "LeftCenter",
    "LeftBottom",
    "RightTop",
    "RightCenter",
    "RightBottom",
  ],
  WIDTH_IN_PERCENTAGE: getStylePropertyList({
    first: 1,
    last: 100,
    incBy: 1,
    postfix: "%",
  }),
  MIN_VALUE: getStylePropertyList({
    first: 1,
    last: 100,
    incBy: 1,
    postfix: "",
  }),
  MAX_VALUE: getStylePropertyList({
    first: 1,
    last: 100,
    incBy: 1,
    postfix: "",
  }),
  MASK_TYPES: {
    MOBILE: "000-000-0000",
    COUNTRY_ISO_CODE: "LL/LLL",
    DATE: "00/00/0000",
    PRODUCT_KEY: "AAAAA-AAAAA-AAAAA-AAAAA",
    DATE_FORMAT_2: "00/LLL/0000",
    DATE_TYPE_3: "00/LL/00",
  },
  WIDTH: getStylePropertyList({
    first: 1,
    last: 300,
    incBy: 1,
  }),
  HEIGHT: getStylePropertyList({
    first: 1,
    last: 300,
    incBy: 1,
  }),
  BUTTON_TYPE: [
    BUTTON_TYPES.GRADIENT_BUTTON,
    BUTTON_TYPES.BACKGROUND_BUTTON,
    BUTTON_TYPES.BORDER_BUTTON,
    BUTTON_TYPES.TEXT_BUTTON,
    BUTTON_TYPES.ACTIVE_BUTTON,
    BUTTON_TYPES.IN_ACTIVE_BUTTON,
    BUTTON_TYPES.IN_PROGRESS_BUTTON,
    BUTTON_TYPES.INFO_GRADIENT_BUTTON,
    BUTTON_TYPES.INFO_BACKGROUND_BUTTON,
  ],
  SPLIT_BUTTON_TYPE: [
    BUTTON_TYPES.SPLIT_GRADIENT_BUTTON,
    BUTTON_TYPES.SPLIT_BACKGROUND_BUTTON,
  ],
  DROPDOWN_BUTTON_TYPE: [
    BUTTON_TYPES.DROPDOWN_GRADIENT_BUTTON,
    BUTTON_TYPES.DROPDOWN_BACKGROUND_BUTTON,
  ],
  TOAST_ICON_TYPE_CLASSES: {
    SUCCESS: {
      PARENT: "e-toast e-toast-success",
      CHILD: "e-toast-success-icon",
    },
    INFO: {
      PARENT: "e-toast e-toast-info",
      CHILD: "e-toast-info-icon",
    },
    WARNING: {
      PARENT: "e-toast e-toast-warning",
      CHILD: "e-toast-warning-icon",
    },
    ERROR: {
      PARENT: "e-toast e-toast-error",
      CHILD: "e-toast-error-icon",
    },
  },
  ORGANIZATION: "organization",
  PROFILE: "profile",
  PORTAL: "portal"
};

export const defaultSyncfusionButtonProps: ButtonComponentProps = {
  cssClass: "",
  children: "Primary Btn",
  borderRadius: "5px",
  borderType: BorderStyleTypes.SOLID,
  fontWeight: "600",
  disabled: false,
};

export const defaultSyncfusionAddIconButtonProps: AddIconComponentProps = {
  cssClass: "e-round",
  iconCss: "e-icons e-plus",
  gradientTextColor1: COLORS.DEFAULT_GRADIENT_1,
  gradientTextColor2: COLORS.DEFAULT_GRADIENT_2,
  borderStyle: BorderStyleTypes.SOLID,
  borderWidth: "3px",
  borderColor: COLORS.DEFAULT_GRADIENT_1,
  isDisabled: false,
};

export const SplitDefaultItems = [
  {
    text: "Paste",
    iconCss: "e-icons e-paste",
  },
  {
    text: "Paste Special",
    iconCss: "e-icons e-paste-special",
  },
  {
    text: "Paste as Formula",
    iconCss: "e-icons e-formula",
  },
  {
    text: "Paste as Hyperlink",
    iconCss: "e-icons e-link",
  },
];

export const defaultSplitButtonProps: SplitButtonComponentProps = {
  content: "Split Button",
  items: SplitDefaultItems,
};
export const defaultDropdownButtonProps: DropdownButtonComponentProps = {
  items: SplitDefaultItems,
  content: "Dropdown Button",
};

export const defaultToggleSwitchButtonProps: ToggleSwitchComponentProps = {
  label: "Toggle Button",
};

export const defaultCheckboxComponentProps: CheckboxComponentProps = {
  checked: true,
  label: "Checkbox",
};
export const defaultRadioButtonComponentProps: CheckboxComponentProps = {
  checked: true,
  label: "Checkbox",
};

export const defaultMultiSelectComponentProps: MultiSelectComponentProps = {
  id: "defaultElement",
  dataSource: [],
  mode: MultiSelectModesTypes.DEFAULT,
  fields: { text: "game", value: "id" },
  placeholder: "Favorite Sports",
  enabled: true,
  widthInPercentage: "100%",
};
export const RichTextEditorTool: string[] = [
  "Bold",
  "Italic",
  "Underline",
  "StrikeThrough",
  "FontName",
  "FontSize",
  "FontColor",
  "BackgroundColor",
  "LowerCase",
  "UpperCase",
  "|",
  "Formats",
  "Alignments",
  "NumberFormatList",
  "BulletFormatList",
  "Outdent",
  "Indent",
  "SuperScript",
  "SubScript",
  "|",
  "CreateTable",
  "CreateLink",
  "Image",
  "FileManager",
  "|",
  "ClearFormat",
  "Print",
  "SourceCode",
  "FullScreen",
  "|",
  "Undo",
  "Redo",
];

export const defaultRichTextEditorComponentProps: RichTextEditorComponentProps =
{
  id: "toolsRTE",
  enableResize: false,
  showCharCount: true,
  enabled: true,
  width: "100%",
  items: RichTextEditorTool,
};

export const RichTextEditorHostUrl =
  "https://ej2-aspcore-service.azurewebsites.net/";
export const defaultTextboxComponentProps: TextboxComponentProps = {
  placeholder: "Enter Name",
  widthInPercentage: "100%",
  isSuccess: false,
  isError: false,
  isWarning: false,
  isRTL: false,
  isSmall: false,
  type: "text"
};

export const defaultTextboxMaskComponentProps: TextboxMaskComponentProps = {
  mask: MaskTypes.DATE,
  isDisabled: false,
  widthInPercentage: "100%",
  label: "",
};

export const defaultTextboxNumericComponentProps: TextboxNumericComponentProps =
{
  value: 0.5,
  min: 0,
  max: 1,
  step: 0.01,
  isDisabled: false,
  widthInPercentage: "100%",
};

export const defaultAutoCompleteComponentProps: AutoCompleteComponentProps = {
  id: "Games",
  placeholder: "Eg: Cricket",
  isEnabled: true,
  widthInPercentage: "100%",
  dataSource: [
    "Badminton",
    "Basketball",
    "Cricket",
    "Football",
    "Golf",
    "Gymnastics",
    "Hockey",
    "Rugby",
    "Snooker",
    "Tennis",
  ],
};

export const defaultComboBoxComponentProps: ComboBoxComponentProps = {
  dataSource: [
    "Badminton",
    "Basketball",
    "Cricket",
    "Football",
    "Golf",
    "Gymnastics",
    "Hockey",
    "Rugby",
    "Snooker",
    "Tennis",
  ],
  placeholder: "Select Games",
  popupHeight: "220",
  value: "Football",
  isEnabled: true,
};

export const defaultIconComponentProps: IconComponentProps = {
  name: "access",
  height: "50",
};

export const defaultTooltipComponentProps: TooltipComponentProps = {
  content: "Tooltip Example",
  position: "BottomCenter",
  children: <p>Tooltip</p>,
};
export const defaultUsernameTagComponentProps = {
  isCircle: true,
  borderRadius: "25px",
  size: "SM",
  iconBgColor: "#bbacac",
  userName: "Techture Global",
  profilePicKey: "",
  nameInitial: "TG",
  fontSize: "20px",
  userNameBackground: "linear-gradient(135deg, #FFFFFF 44.5%, #F0F5F9 129%)"
}

export const defaultFeedbackToastComponentProps: FeedbackToastComponentProps = {
  position: {
    X: "Right",
    Y: "Bottom",
  },
  timeOut: 3000,
  width: 400,
  content: "<div>Your message has been <b>sent</b> successful.</div>",
  title: "",
  isActionButton: false,
  actionBtnText: "UNDO",
};

export const defaultCardComponentProps: CardComponentProps = {
  cardBody: "Card Component",
  cardHeaderText: "Card Header",
  headerLinkText: "View all",
  width: "100%",
  enableMaxMinIcon: true,
  maxMinIconName: "screenmaximize",
  enableShareIcon: true,
  enableThreeDotsIcon: true,
  dataBound: null,
  onClick: null,
  background: "",
};

export const SparklineGraphComponentDefaultProps: SparklineGraphComponentProps = {
  id: "sparkLine-1",
  dataSource: [
    { x: 0, xval: "2005", yval: 20090440 },
    { x: 1, xval: "2006", yval: 20264080 },
    { x: 2, xval: "2007", yval: 20434180 },
    { x: 3, xval: "2008", yval: 21007310 },
    { x: 4, xval: "2009", yval: 21262640 },
    { x: 5, xval: "2010", yval: 21515750 },
    { x: 6, xval: "2011", yval: 21766710 },
    { x: 7, xval: "2012", yval: 22015580 },
    { x: 8, xval: "2013", yval: 22262500 },
    { x: 9, xval: "2014", yval: 22507620 },
  ],
  xName: "xval",
  yName: "yval"
}

export const defaultDragListComponentsProps: DragListComponentProps = {
  data1: [
    { Name: "Transmittals", Code: "AU" },
    { Name: "Issues", Code: "BM" },
    { Name: "Portal Settings", Code: "CA" },
    { Name: "Pending Issues", Code: "CM" },
    { Name: "Approved Transmittals", Code: "DK" },
    { Name: "Estimates", Code: "FR" },
    { Name: "Projects Lists", Code: "FI" },
    { Name: "My Project", Code: "DE" },
    { Name: "Pending Issues", Code: "HK" },
  ],
  data2: [
    { Name: "In Review Tasks", Code: "AU" },
    { Name: "Approved Tasks", Code: "BM" },
    { Name: "Pending Projects", Code: "CA" },
    { Name: "Active Issues", Code: "CM" },
    { Name: "IN review tasks", Code: "DK" },
    { Name: "Edit Profile", Code: "FR" },
  ],
  fields: { text: "Name" }
}

export const defaultMapComponentProps: MapComponentProps = {
  latitude: 28.4595,
  longitude: 77.0266,
  zoomFactor: 9,
  enable: false,
  height: "100%",
  width: '100%',
  name: 'Delhi',
  isToolTipVisible: true,
}

export const cssStyleColorOther = [
  {
    displayName: "Info Light Blue",
    name: "--info-light-blue",
    colorCode: "#c7e0f4",
  },
  {
    displayName: "Info Lighter Blue",
    name: "--info-lighter-blue",
    colorCode: "#deecf9",
  },
  {
    displayName: "Light Blue",
    name: "--light-blue",
    colorCode: "#c6d0df",
  },
  {
    displayName: "Lighter Gray",
    name: "--lighter-gray",
    colorCode: "#eaeaea",
  },
  {
    displayName: "Disabled Text Gray",
    name: "--disabled-text-gray",
    colorCode: "rgba(25, 32, 72, 0.5)",
  },
  {
    displayName: "Disabled Border",
    name: "--disabled-border",
    colorCode: "#f4f4f4",
  },
  {
    displayName: "Light Gray",
    name: "--light-gray",
    colorCode: "#e2e9f3",
  },
  {
    displayName: "Box Shadow",
    name: "--box-shadow",
    colorCode: "rgba(0, 0, 0, 0.15)",
  },
  {
    displayName: "White Opacity",
    name: "--white-opacity",
    colorCode: "rgba(255, 255, 255, 0.5)",
  },
  {
    displayName: "TextBox Inner Border",
    name: "--inner-border",
    colorCode: "#7bd2fc",
  },
  {
    displayName: "Center Border",
    name: "--center-border",
    colorCode: "#212121",
  },
  {
    displayName: "Border Gray",
    name: "--border-gray",
    colorCode: "#d8d8d8",
  },
  {
    displayName: "Light Black",
    name: "--light-black",
    colorCode: "#333",
  },
  {
    displayName: "Soft Gray",
    name: "--soft-gray",
    colorCode: "#c8c8c8",
  },

];

export const cssStyleColoursPrimary = [
  {
    displayName: "Primary Gradient",
    name: "Primary Gradient",
    colorCode1: "#6bbbe3",
    colorCode2: "#3e8ab0",
    type: "gradient",
  },
  {
    displayName: "Surface Gradient",
    name: "Surface Gradient",
    colorCode1: "FFFFFF",
    colorCode2: "F0F5F9",
    type: "gradient",
  },
  {
    displayName: "Surface Stroke Gradient",
    name: "Surface Stroke Gradient",
    colorCode1: "#ECF3FE",
    colorCode2: "rgb(236, 243, 254, 0.15)",
    type: "gradient",
  },
  {
    displayName: "Primary Gradient Color1",
    name: "--primary-gradient-color1",
    colorCode: "#6bbbe3",
  },
  {
    displayName: "Primary Gradient Color2",
    name: "--primary-gradient-color2",
    colorCode: "#3e8ab0",
  },
  {
    displayName: "Surface Gradient Color1",
    name: "--surface-gradient-color1",
    colorCode: "FFFFFF",
  },
  {
    displayName: "Surface Gradient Color2",
    name: "--surface-gradient-color2",
    colorCode: "F0F5F9",
  },
  {
    displayName: "Surface Stroke Gradient Color1",
    name: "--surface-stroke-gradient-color1",
    colorCode: "rgb(236, 243, 254, 0.15)",
  },
  {
    displayName: "Surface Stroke Gradient Color2",
    name: "--surface-stroke-gradient-color2",
    colorCode: "rgb(236, 243, 254, 0.15)",
  },
  {
    displayName: "Primary Text",
    name: "--primary-text",
    colorCode: "#192048",
  },
  {
    displayName: "Surface Neutral",
    name: "--surface-neutral",
    colorCode: "#f0f5f9",
  },
  {
    displayName: "Surface White",
    name: "--surface-white",
    colorCode: "#ffffff",
  },
  {
    displayName: "Text Light",
    name: "--text-light",
    colorCode: "#666a84",
  },
  {
    displayName: "Selection",
    name: "--selection",
    colorCode: "#caecff",
  },
  {
    displayName: "Button Light",
    name: "--button-light",
    colorCode: "#ecf3fe",
  },
];

export const cssStyleColoursCommunication = [
  {
    displayName: "Positive Communication",
    name: "--positive-communication",
    colorCode: "#45cf80",
  },
  {
    displayName: "Negative Communication",
    name: "--negative-communication",
    colorCode: "#e75555",
  },
  {
    displayName: "Warning",
    name: "--warning",
    colorCode: "#ffcf55",
  },
];

export const cssStyleColorsSystem = [
  {
    displayName: "System Colour-1",
    name: "--system-colour-1",
    colorCode: "#1080d6",
  },
  {
    displayName: "System Colour-2",
    name: "--system-colour-2",
    colorCode: "#38ba84",
  },
  {
    displayName: "System Colour-3",
    name: "--system-colour-3",
    colorCode: "#e75555",
  },
  {
    displayName: "System Colour-4",
    name: "--system-colour-4",
    colorCode: "#6895b6",
  },
  {
    displayName: "System Colour-5",
    name: "--system-colour-5",
    colorCode: "#86b785",
  },
  {
    displayName: "System Colour-6",
    name: "--system-colour-6",
    colorCode: "#cb4444",
  },
  {
    displayName: "System Colour-7",
    name: "--system-colour-7",
    colorCode: "#45cf80",
  },
  {
    displayName: "System Colour-8",
    name: "--system-colour-8",
    colorCode: "#e75555",
  },
  {
    displayName: "System Colour-9",
    name: "--system-colour-9",
    colorCode: "#ffcf55",
  },
  {
    displayName: "System Colour-10",
    name: "--system-colour-10",
    colorCode: "#45cf80",
  },
  {
    displayName: "System Colour-11",
    name: "--system-colour-11",
    colorCode: "#e75555",
  },
  {
    displayName: "System Colour-12",
    name: "--system-colour-12",
    colorCode: "#ffcf55",
  },
  {
    displayName: "System Colour-13",
    name: "--system-colour-13",
    colorCode: "#624fff",
  },
  {
    displayName: "System Colour-14",
    name: "--system-colour-14",
    colorCode: "#b8ce1b",
  },
  {
    displayName: "System Colour-15",
    name: "--system-colour-15",
    colorCode: "#1ec7f8",
  },
  {
    displayName: "System Colour-16",
    name: "--system-colour-16",
    colorCode: "#5b5496",
  },
  {
    displayName: "System Colour-17",
    name: "--system-colour-17",
    colorCode: "#6faa8e",
  },
  {
    displayName: "System Colour-18",
    name: "--system-colour-18",
    colorCode: "#5bb8ff",
  },
  {
    displayName: "System Colour-19",
    name: "--system-colour-19",
    colorCode: "#ff5b1b",
  },
  {
    displayName: "System Colour-20",
    name: "--system-colour-20",
    colorCode: "#d4066d",
  },
  {
    displayName: "System Colour-21",
    name: "--system-colour-21",
    colorCode: "#0570ab",
  },
  {
    displayName: "System Colour-22",
    name: "--system-colour-22",
    colorCode: "#ec9e80",
  },
  {
    displayName: "System Colour-23",
    name: "--system-colour-23",
    colorCode: "#bb598a",
  },
  {
    displayName: "System Colour-24",
    name: "--system-colour-24",
    colorCode: "#7d7d7d",
  },
  {
    displayName: "System Colour-25",
    name: "--system-colour-25",
    colorCode: "#3587f8",
  },
  {
    displayName: "System Colour-26",
    name: "--system-colour-26",
    colorCode: "#fecd04",
  },
  {
    displayName: "System Colour-27",
    name: "--system-colour-27",
    colorCode: "#ff6d6f",
  },
  {
    displayName: "System Colour-28",
    name: "--system-colour-28",
    colorCode: "#607b9f",
  },
  {
    displayName: "System Colour-29",
    name: "--system-colour-29",
    colorCode: "#3b997e",
  },
  {
    displayName: "System Colour-30",
    name: "--system-colour-30",
    colorCode: "#d27373",
  },
  {
    displayName: "System Colour-31",
    name: "--system-colour-31",
    colorCode: "#8a60f7",
  },
  {
    displayName: "System Colour-32",
    name: "--system-colour-32",
    colorCode: "#f7156d",
  },
  {
    displayName: "System Colour-33",
    name: "--system-colour-33",
    colorCode: "#fe19b2",
  },
  {
    displayName: "System Colour-34",
    name: "--system-colour-34",
    colorCode: "#7b5dc8",
  },
  {
    displayName: "System Colour-35",
    name: "--system-colour-35",
    colorCode: "#b256a9",
  },
  {
    displayName: "System Colour-36",
    name: "--system-colour-36",
    colorCode: "#bb4dff",
  },
];

export const defaultAvatarComponentProps = {
  isCircle: false,
  size: "SM",
  profilePicKey: "",
  userName: "Techture Global",
};

export const zeplinComponentUrl = {
  BUTTONS: {
    MainButton:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd3d150f610e1c44cf8",
    MainButtonDisabled:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd78fd9821136b17601",
    StrokeButton:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd5ddf88b2811b0a667",
    StrokeButtonDisabled:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd595142924d29765b5",
    LightButton:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd78a12f910a785982d",
    LightButtonDisabled:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd755529d2da73dcb6a",
    InvisibleButton:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ddaca885128c6820b52",
    InvisibleButtonDisabled:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0dd8d150f610e1c44d09",
    ACTIVE_BTN: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b477a3c413a9462175",
    ACTIVE_BTN_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b5f1bca915a443fd27",
    IN_ACTIVE_BTN: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b51077e51448b35234",
    IN_ACTIVE_BTN_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b578fa3413eca4065e",
    IN_PROGRESS_BTN: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b4829043196104180b",
    IN_PROGRESS_BTN_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396e2b463ad68166b14ddb1",
    GRADIENT_INFO: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a4b72bdf5e914e14353fe",
    GRADIENT_INFO_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a4b73bdf5e914e1435414",
    BACKGROUND_INFO: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a4b73181ca513fe88e6a9",
    BACKGROUND_INFO_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a4b731e130614ccfefa9a",
  },
  TOAST: {
    POSITIVE_PROGRESS_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee2dff5d32a9ed9309d",
    POSITIVE_PROGRESS:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee2c371de124f630b4e",
    POSITIVE_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee3584df72c12ea85ba",
    NEGATIVE_PROGRESS_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee40ab93011af053fd8",
    NEGATIVE_PROGRESS:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee368a05a27736fc792",
    NEGATIVE_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee34124e9119fc42771",
    WARNING_PROGRESS_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee3caea682cd0facae2",
    WARNING_PROGRESS:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee21a596f10c948ab7e",
    WARNING_ACTION:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0ee38fd9821136b1780f",
  },
  MODAL: {
    SIMPLE_ACTION_MODAL:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0e1732385525d786f347",
    EXPORT_ISSUE_MODAL:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0e184bc7b312903371d0",
    CHANGE_STATUS_MODAL:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0e19ae73f22c377912ff",
    TEXT_INPUT_ACTION_MODAL:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0e1acaea682cd0fac976",
    ADD_NEW_STATUS_MODAL:
      "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0e191071292acb59a351",
  },
  TOOLTIP: {
    TOP_LEFT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a538ba03d7b139ac399d3",
    TOP_CENTER: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a538ac0da7f13d748aa85",
    TOP_RIGHT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a538af502f0139e4804d8",
    BOTTOM_LEFT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a53896d5e051626a7d194",
    BOTTOM_CENTER: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a538a96089c144ec5ef55",
    BOTTOM_RIGHT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636a5389525ba1189f28565d",
    LEFT_TOP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f5863890821ad1e59b6e",
    LEFT_CENTER: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f586de376615592b7a3b",
    LEFT_BOTTOM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f5843f4c8f1536cde248",
    RIGHT_TOP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f585fb24991577c341d9",
    RIGHT_CENTER: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f587730f31144ae87a36",
    RIGHT_BOTTOM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396f58a8a61d619894546f6",
  },
  SPLIT_BUTTON: {
    GRADIENT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6331ad997cc0c61cbe3d6a4f",
    GRADIENT_ICON: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a13a2c28d7fc13de9cf952",
    BACKGROUND: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6331ad9aface0914817733bb",
    BACKGROUND_ICON: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a13a2b9cd67e1bfd86bbea",
  },
  ADD_BUTTON: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=636b39126569ee2f73c2ac71",
  TEXTBOX: {
    NumericTextbox: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c73ab45331bbab9b6772",
    NumericTextboxDisabled: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c739c2149b155cab6c11",
    MaskTextbox: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c73699035c142a89188b",
    MaskTextboxDisabled: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c739a55ce1bcb3162b2a",
    TextTextBox: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c7375fd4d7b9a171836b",
    TextTextBoxDisabled: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c73615d519bbfb667be6",
  },
  DROPDOWN_BUTTON: {
    GRADIENT: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c5214acb43ba18b7f438",
    GRADIENT_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c52230abfc1652f3e6bc",
    BACKGROUND: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c5228429fc1d3f9efb90",
    BACKGROUND_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c52230abfc1652f3e6bc",
  },
  USERNAME_TAG: {
    IMAGE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a186a5f29dba145ce27f4c",
    INITIAL: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a186a7151f6113fe1b334e"
  },
  RADIO_BUTTON: {
    CHECKED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c502bd6dcf1509c9ae7b",
    UN_CHECKED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c5027984e5bab5ed6b46",
  },
  CHECKBOX: {
    CHECKED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63932b56a94d7cbc8b7bb8b4",
    UN_CHECKED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63932cef888e16bd78f896b0",
  },
  RICH_TEXT_EDITOR: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392bef7020d9b15ba06846b",
  AUTO_COMPLETE: {
    ENABLE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392bc98c048b9b9ecd0f760",
    DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392bc98489cdb18387fb57f"
  },
  COMBO_BOX: {
    ENABLE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6391ea1f53c608bc41277ff9",
    DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a13916a71e2a16352e32cd"
  },
  MULTISELECT: {
    ENABLE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a13d5e0840ec1411f8e3c9",
    DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a13d5c40e2c814f61cf295"
  },
  SLIDER: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=62ff0f4fd32760290075af9d",
  AVATAR: {
    ROUNDED_IMAGE_XS: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18e714f00179eb69e67",
    ROUNDED_IMAGE_SM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18f714f00179eb69e76",
    ROUNDED_IMAGE_LG: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18e7e6b13b8ee10c0a4",
    ROUNDED_IMAGE_XL: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18f002089bd483bf0f1",

    ROUNDED_INITIAL_XS: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c19058fca5bb36c57261",
    ROUNDED_INITIAL_SM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c1904953671d1d64d2b7",
    ROUNDED_INITIAL_LG: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18f560ce2b70dce87d2",
    ROUNDED_INITIAL_XL: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c19030abfc1652f3d99c",

    SQUIRE_IMAGE_XS: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba20e8666c1731ff9107",
    SQUIRE_IMAGE_SM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba2154958518b8b02820",
    SQUIRE_IMAGE_LG: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba22d80dfb15f3c9eba8",
    SQUIRE_IMAGE_XL: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba236ed50314c76b6bdd",

    SQUIRE_INITIAL_XS: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba1fd463d8169dfd7fad",
    SQUIRE_INITIAL_SM: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba1f6a7c0f16ab86e923",
    SQUIRE_INITIAL_LG: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba1f6ab1a6144fb07d71",
    SQUIRE_INITIAL_XL: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ba20e2fbca139fb13966",
  },
  AVATAR_GROUP: {
    ROUNDED_INITIAL_GROUP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c1900033d8b97a406093",
    ROUNDED_IMAGE_GROUP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6392c18ea84a0eb874132114",
    SQUIRE_INITIAL_GROUP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ddd4757f4315bb4abf21",
    SQUIRE_IMAGE_GROUP: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ddd611506116ac1bdb13",
  },
  GRID_COMPONENT: {
    SIMPLE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=6396ec86a977c014e4228b6b"
  },
  TOGGLE_SWITCH: {
    ACTIVE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a1417e123a4813d6966551",
    ACTIVE_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a1417e646a1d15de52354e",
    INACTIVE: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a14181cdf0811ccb9f9946",
    INACTIVE_DISABLED: "zpl://components?stid=62fe3a973b1c8a2a3f4f00ea&coid=63a14181a71b0b1694df8baa"
  }
};

export const defaultSliderComponentProps: SliderComponentProps = {
  enabled: true,
  min: 30,
  max: 100,
  value: 30,
  width: 200,
  gradientColor1: "var(--primary-gradient-color1)",
  gradientColor2: "var(--primary-gradient-color2)",
  handleGradientColor1: "var(--primary-gradient-color1)",
  handleGradientColor2: "var(--primary-gradient-color2)",
};

export const defaultGridComponentProps: any = {
  id: "overviewgrid",
  dataSource: [
    {
      "Check": true,
      "EmployeeID": 10001,
      "Employees": "Kathryn Fuller",
      "Designation": "Designer",
      "Location": "Mexico",
      "Status": "Active",
      "Trustworthiness": "Sufficient",
      "Rating": 3,
      "Software": 11,
      "EmployeeImg": "usermale",
      "CurrentSalary": 1612,
      "Address": "Luisenstr. 48",
      "Mail": "kathryn98@@jourrapide.com"
    },
    {
      "Check": false,
      "EmployeeID": 10002,
      "Employees": "Tamer Fuller",
      "Designation": "Manager",
      "Location": "Canada",
      "Status": "Inactive",
      "Trustworthiness": "Perfect",
      "Rating": 5,
      "Software": 52,
      "EmployeeImg": "userfemale",
      "CurrentSalary": 10642,
      "Address": "Rua do Paço, 67",
      "Mail": "tamer10@@arpy.com"
    },
    {
      "Check": false,
      "EmployeeID": 10003,
      "Employees": "Martin Nancy",
      "Designation": "Project Lead",
      "Location": "USA",
      "Status": "Inactive",
      "Trustworthiness": "Insufficient",
      "Rating": 1,
      "Software": 33,
      "EmployeeImg": "userfemale",
      "CurrentSalary": 38268,
      "Address": "2, rue du Commerce",
      "Mail": "martin58@@mail.com"
    }
  ],
  loadingIndicator: { indicatorType: 'Shimmer' },
  selectionSettings: {
    persistSelection: true,
    type: "Multiple",
    checkboxOnly: true
  },
  filterSettings: {
    type: 'Menu'
  },
  enableHover: false,
  rowHeight: 38,
  height: '600',
  allowFiltering: true,
  allowSorting: true,
  allowSelection: true,
  enableHeaderFocus: true,
  columnsDirectiveList: [
    {
      type: "checkbox",
      width: '60',
      field: 'Check',
      allowFiltering: false,
      allowSorting: false,
    },
    {
      field: 'EmployeeID',
      visible: false,
      headerText: 'Employee ID',
      isPrimaryKey: true,
      width: '130'
    },
    { field: 'Employees', headerText: 'Employee Name', width: '230', clipMode: 'EllipsisWithTooltip' },
    { field: 'Designation', headerText: 'Designation', width: '170', clipMode: 'EllipsisWithTooltip' },
    { field: 'Mail', headerText: 'Mail', width: '230' },
    { field: 'Location', headerText: 'Location', width: '140' },
    { field: 'Status', headerText: 'Status', width: '130' },
    { field: 'Trustworthiness', headerText: 'Trustworthiness', width: '160' },
    { field: 'Rating', headerText: 'Rating', width: '160' },
    { field: 'Software', allowFiltering: false, allowSorting: false, headerText: 'Software Proficiency', width: '180', format: 'C2' },
    { field: 'CurrentSalary', headerText: 'Current Salary', width: '160', format: 'C2' },
    { field: 'Address', headerText: 'Address', width: '240', clipMode: "EllipsisWithTooltip" }
  ] as any,
  injectServices: [Filter, VirtualScroll, Sort]
}

export const errorIcon = '<img class="inline-flex pr-2" src="/assets/icons/WrongIcon.svg" alt="Error Image" />';

export const passwordValidationChecks = {
  required: [true, `${errorIcon} Please enter password.`],
  minLength: [6, `${errorIcon} Password should have at least 6 characters.`],
  regex: [/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?]).{6,}/, `${errorIcon} Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.`],
};

export const userProfileFormFieldNames: any = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  oldPassword: "oldPassword",
  newPassword: "newPassword",
  confirmNewPassword: "confirmNewPassword",
  profilePic: 'profilePic',
  designation: "designation",
  location: "location",
  contactNo: "contactNo"

};

export const userProfileFormFieldPlaceholder: any = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email Address",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  designation: "Designation",
  location: "Location",
  contactNo: "Contact No."
};
export const userProfileFieldParentWrapperClass: string = "textBoxWrapper";

export const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
export const validSignDocsTypes = [...validImageTypes, "application/pdf"];

export const SignTypeValues = {
  TYPE_NAME_INITIAL: "TYPE_NAME_INITIAL",
  DRAW_ON_SCREEN: "DRAW_ON_SCREEN",
  IMPORT_AS_IMAGE: "IMPORT_AS_IMAGE",
  EMBED_INSIDE_FILE: "EMBED_INSIDE_FILE",
  ATTACH_SIGNED_DOCUMENT: "ATTACH_SIGNED_DOCUMENT",
}

export const SignTypes = [
  // {
  //   name: "Type Name or Initials",
  //   value: SignTypeValues.TYPE_NAME_INITIAL
  // },
  {
    name: "Draw On Screen",
    value: SignTypeValues.DRAW_ON_SCREEN
  },
  // {
  //   name: "Import as image",
  //   value: SignTypeValues.IMPORT_AS_IMAGE
  // }, {
  //   name: "Embed inside file",
  //   value: SignTypeValues.EMBED_INSIDE_FILE
  // }, 
  {
    name: "Attach Signed Document",
    value: SignTypeValues.ATTACH_SIGNED_DOCUMENT
  }
]

export const fileDefaultStatus = {
  IN_REVIEW: "In review",
  REJECTED: "Rejected",
  APPROVED: "Approved",
}

export const SETTING_RENDER_MODE = {
  READ_ONLY: "read-only",
  EDIT: "edit"
}

export const settingManagementFormFieldNames: any = {
  id: 'id',
  logo: "logo",
  name: "name",
  about: "about",
  location: "location",
  website: "website",
  pan: "pan",
  noOfEmployee: "noOfEmployee",
  orgId: "orgId",
};


