import { useEffect, useId, useRef } from 'react';
import css from './Tool.module.css';
import ChangeColor from './ChangeColor';
import TooltipComponent from '../../../cubeComponent/html/tooltip';
import classNames from 'classnames';

const Tool = ({
  Icon,
  position,
  isVisible = true,
  isEnabled = true,
  onClick,
  children,
  menuShow,
  color,
  Tooltip,
  opacity,
  secondClick,
  type,
  customKey,
  Svg,
  className = '',
  style = {},
  setGradient = true,
}) => {
  const uid = useId();

  const svgRef = useRef(null);

  useEffect(() => {
    if (Svg && svgRef.current) {
      let childNodes;
      const id = `tool_${uid}`;
      svgRef.current.setAttribute('toolId', `${uid}`);
      if (svgRef.current.childNodes[0].tagName == 'g') {
        childNodes = svgRef.current.childNodes[0].childNodes;
      }
      else {
        childNodes = svgRef.current.childNodes;
      }
      childNodes.forEach((node) => {
        let type = 'fill';
        if (node.attributes['stroke-width']) {
          type = 'stroke';
        }
        node.setAttribute(type, `url("#${id}")`);
      });

      setGradient ? svgRef.current.innerHTML += `<linearGradient id="${id}" gradientTransform="rotate(45)">
                <stop offset="38%" id="start_${id}" stop-color="${color}" />
                <stop offset="92%" id="stop_${id}" stop-color="${ChangeColor(color)}" />
            </linearGradient>` : svgRef.current.innerHTML += `<linearGradient id="${id}" gradientTransform="rotate(100)">
            <stop offset="100%" id="start_${id}" stop-color="${color}" />
            <stop offset="100%" id="stop_${id}" stop-color="${ChangeColor(color)}" />
        </linearGradient>`;
    }
  }, [Svg, color, setGradient]);

  useEffect(() => {
    if (Svg && svgRef.current) {
      const id = `tool_${svgRef.current.getAttribute('toolId')}`;
      svgRef.current.getElementById(`start_${id}`).setAttribute('stop-color', `${color}`);
      svgRef.current.getElementById(`stop_${id}`).setAttribute('stop-color', `${ChangeColor(color)}`);
    }
  }, [color, Svg]);

  const handleOnClick = (e, triggerOnClick) => {
    if (triggerOnClick && onClick && isEnabled) {
      onClick(e);
    }
  };

  useEffect(() => {
    if (Svg?.render?.name == 'SvgSpacesIcon') {
      console.log('space remounted');
    }
  }, []);

  return (
    <div
      style={{ opacity: isEnabled ? 1 : 0.4, ...style }}
      data-tip
      data-for={`tip_${uid}`}
      key={customKey}
      className={`${className} ${!isVisible ? 'hidden' : 'flex'} cursor-pointer self-center flex-col-reverse items-center`}
      onClick={(e) => handleOnClick(e, isEnabled)}
      type={type}
      data-testid={`designViewer_${Tooltip}`}
    >
      <TooltipComponent
        content={Tooltip}
        direction="right"
        position={position || 'RightCenter'}
        cssClass={classNames({
          [css.hiddenTooltip]: !Tooltip,
        })}
      >
        {Svg ? (
          <Svg ref={svgRef} size="1.3rem" color={color} opacity={opacity} secondClick={secondClick} setGradient={setGradient} />
        ) : (
          <Icon size="1.3rem" color={color} opacity={opacity} secondClick={secondClick} />
        )}
      </TooltipComponent>
      {children && menuShow && <>{children}</>}
    </div>
  );
};

export default Tool;
