import css from './UploadFileMinimiser.module.css';
import { useState } from 'react';
import arrowDown from '../../../assets/icons/svg/arrowDown.svg';
import close from '../../../assets/icons/svg/close.svg';
import maximize from '../../../assets/icons/svg/Maximize.svg';
import { useDispatch, useSelector } from 'react-redux';
import tickMark from '../../../assets/icons/svg/greenTickMark.svg';
import arrowUp from '../../../assets/icons/svg/ArrowsUp.svg';
import {
 closeFileUpload, emptyFileUploadData, maximiseFileUpload, removeFileUploadData,
 setIsFileVersionUploading,
} from '../../../actions/fileUpload';
import { getFileImage } from '../../../services/utils/helpers';

const EXPANDED_VIEW = 'EXPANDED_VIEW';
const COMPACT_VIEW = 'COMPACT_VIEW';

const UploadFileMinimiser = () => {
  const [view, setView] = useState(COMPACT_VIEW);
  const dispatch = useDispatch();

  const { fileUploadList, fileUploadState } = useSelector((state) => state);

  const handleArrowDown = () => {
    setView((previous) => {
      if (previous === COMPACT_VIEW) {
        return EXPANDED_VIEW;
      }
      return COMPACT_VIEW;
    });
  };

  const handleClose = () => {
    dispatch(setIsFileVersionUploading(false));
    dispatch(closeFileUpload());
    dispatch(emptyFileUploadData());
  };

  const getProgress = () => {
    const progress = (fileUploadList.filter((file) => file.progressCompleted).length) / (fileUploadList.length);
    return progress * 100;
  };

  const cancelUpload = (event) => {
    const index = event.currentTarget.attributes['data-set'].value;
    dispatch(removeFileUploadData(fileUploadList[index]));
  };

  const handleMaximise = () => {
    dispatch(maximiseFileUpload());
  };

  const headerTemplate = () => (
    <div className="flex flex-col mb-2">
      <div className="flex flex-row justify-between">
        <p className="text-2xl">
          <strong>{`Uploading ${fileUploadList.length} items`}</strong>
        </p>
        <div className="flex flex-row gap-4 items-center">
          <img src={maximize} className="h-5 w-5 cursor-pointer" alt="Maximize" onClick={handleMaximise} />
          <img src={view === EXPANDED_VIEW ? arrowDown : arrowUp} className="h-5 w-5 cursor-pointer" onClick={handleArrowDown} alt="Arrow Down" />
          <img src={close} className="h-4 w-4 cursor-pointer" onClick={handleClose} alt="Close" />
        </div>
      </div>
      <p className="text-base">
        {`Completed (${fileUploadList.filter((file) => file.progressCompleted).length}/${fileUploadList.length})`}
      </p>
    </div>
  );

  return (
    <>
      {fileUploadState === 'MINIMISED' && fileUploadList.length ? (
        <div className={css.root}>
          {view === EXPANDED_VIEW ? (
            <div className={css.uploadingFiles}>
              {headerTemplate()}
              <div>
                {fileUploadList.map((file, fileIndex) => (
                  <div key={`${file.name}_${fileIndex}`} className={css.fileDetails}>
                    <div className="flex items-center mb-4 gap-2.5">
                      <img src={getFileImage(file)} className={css.fileImage} />
                      <div className="flex flex-col gap-2.5 grow">
                        <div className="flex justify-between items-center">
                          <p className="text-base font-bold">{file.name}</p>
                          <img src={close} data-set={fileIndex} className="cursor-pointer h-2.5 w-2.5" onClick={cancelUpload} />
                        </div>
                        <div className={css.progessDiv}>
                          {file.duplicateFileStatus?.found === false && file.error === false && file.progressCompleted === false && !file.isMetadataPending && file.triggerFileUpload ? (
                            <div key={`progress_${file.name}`} className={css.progressWrapper}>
                              <div className={css.fileUploadStatusWrapper}>
                                <div>{file.status}</div>
                                <div>{`${file.progress}%`}</div>
                              </div>
                              <div className={css.progressBody}>
                                <div className={css.progressArea} style={{ width: `${file.progress}%` }} />
                              </div>
                            </div>
                          ) : file.progressCompleted ? (
                            <div className="flex gap-1 items-center">
                              <img src={tickMark} className="h-5 w-5" />
                              <div className="text-base">Upload Complete</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={css.mainBox}>
              {headerTemplate()}
              <div className={css.progressBarContainer}>
                <div className={css.progressBar} style={{ width: `${getProgress()}%` }} />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default UploadFileMinimiser;
