import { FC, forwardRef } from "react";
import { ButtonComponentProps } from "../../interface";
import { ButtonComponent as SyncfusionButtonComponent, ButtonModel } from "@syncfusion/ej2-react-buttons";

import {
  defaultSyncfusionButtonProps,
} from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import {
  get_btn_other,
  get_button_component_custom_style,
  get_info_background_btn,
  get_normal_background_btn,
  get_normal_border_btn,
  get_normal_gradient_btn,
  get_normal_text_btn,
} from "../../constants/globalFunctions";

const ButtonComponent: FC<ButtonComponentProps & ButtonModel> = forwardRef((props: ButtonComponentProps & ButtonModel, ref) => {
  const useStyles = createUseStyles({
    gradientButton: get_normal_gradient_btn(),
    backgroundButton: get_normal_background_btn(),
    borderButton: get_normal_border_btn(),
    textButton: get_normal_text_btn(),
    activeButton: get_btn_other(props.buttonType as string),
    inActiveButton: get_btn_other(props.buttonType as string),
    inProgressButton: get_btn_other(props.buttonType as string),
    infoGradientButton: get_normal_gradient_btn(true),
    infoBackgroundButton: get_info_background_btn(),
  });

  const ButtonComponentStyle = get_button_component_custom_style({
    ...props
  });

  const classes = useStyles() as any;

  /******************* 
    @Purpose : Button Component
    @Parameter : ButtonComponentProps
    @Author : Techture
  *******************/
  return (
    <SyncfusionButtonComponent
      {...props}
      cssClass={`${classes[`${props.buttonType}`]}`}
      style={ButtonComponentStyle}
      ref={ref}
    >
      {props.children}
    </SyncfusionButtonComponent>
  );
});
ButtonComponent.defaultProps = defaultSyncfusionButtonProps;
export default ButtonComponent;
