import { RiNotificationOffLine } from 'react-icons/ri';
import {
  useEffect, useState, useRef, useId,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
 getNotifications, readNotification, getNotificationsCount, setShowNotification,
} from '../../actions/notification';
import css from './index.module.css';
import { getNotificationParams } from './helper';
import NotificationCard from './NotificationCard';
import { showToastNotification } from '../../actions/toast';
import { TOAST_TYPE_SUCCESS } from '../../services/constants';
import { successReadNotificationToast } from '../../actions/bcfData';

const Notification = (props) => {
  const showNotification = useSelector((state) => state.showNotification);
  const dispatch = useDispatch();

  const [fetchedData, setFetchedData] = useState({});
  const [newNotifications, setNewNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const notificationStatus = useRef(showNotification);

  const handleClick = (e) => {
    if (!e.target.closest('.preventNotificationClose') && notificationStatus.current) {
      closeNotificationModal();
    }
  };

  const closeNotificationModal = () => {
    dispatch(setShowNotification(false));
  };

  const dismissNotification = async (id) => {
    const response = await readNotification([id]);
    if (response) {
      const countPreviousNotificationsRequired = Math.max(
        previousNotifications.length >= 4 && previousNotifications.length <= 7
          ? previousNotifications.length + 1
          : previousNotifications.length,
        4,
      );
      const fetchedNotificationsData = await getNotifications(4, countPreviousNotificationsRequired);
      setFetchedData(fetchedNotificationsData);
      dispatch(getNotificationsCount(['NOTIFICATION_UNREAD']));
      dispatch(successReadNotificationToast([id]));
    }
  };

  const handleNotifications = async () => {
    if (showNotification) {
      const countPreviousNotificationsRequired = Math.max(previousNotifications.length, 4);
      const fetchedNotificationsData = await getNotifications(4, countPreviousNotificationsRequired);
      setFetchedData(fetchedNotificationsData);
    }
  };

  useEffect(() => {
    handleNotifications();
  }, [showNotification]);

  useEffect(() => {
    notificationStatus.current = showNotification;
  }, [showNotification]);

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const newNotificationsData = fetchedData.new;
    const previousNotificationsData = fetchedData.previous;

    if (newNotificationsData) {
      const newData = newNotificationsData.result.map((notification) => getNotificationParams(notification));
      setNewNotifications(newData);
    }

    if (previousNotificationsData) {
      const oldData = previousNotificationsData.result.map((notification) => getNotificationParams(notification));
      setPreviousNotifications(oldData);
    }
  }, [fetchedData]);

  useEffect(() => {
    dispatch(getNotificationsCount(['NOTIFICATION_UNREAD']));
  }, []);

  return (
    <>
      {showNotification && (
        <div className={`${css.card} preventNotificationClose`}>
          {showNotification && newNotifications.length > 0 && (
          <div className={css.container}>
            <NotificationCard
              closeNotificationModal={closeNotificationModal}
              title="New"
              notificationsList={newNotifications}
              dismissNotification={dismissNotification}
              {...props}
            />
          </div>
          )}

          {showNotification && previousNotifications.length > 0 && (
          <div className={css.container}>
            <NotificationCard
              closeNotificationModal={closeNotificationModal}
              title="Earlier"
              notificationsList={previousNotifications}
              dismissNotification={dismissNotification}
              {...props}
            />
          </div>
          )}

          {showNotification && newNotifications.length === 0 && previousNotifications.length === 0 && (
          <div className={css.noNotificationContainer}>
            <RiNotificationOffLine className={css.noNotificationIcon} />
            <p className={css.noNotificationTitle}>No Notifications right now!</p>
          </div>
          )}
          <div className={css.viewAllTitleDiv}>
            <Link to="/notifications" className={css.viewAllTitle}>
              <span onClick={closeNotificationModal}>View All Notifications</span>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
