const initialIssuesState = {};

export function fileVersions(state = initialIssuesState, action) {
  switch (action.type) {
    case 'SET_FILE_VERSION_DATA': {
      const tempState = { ...state };
      tempState[action.data.fileId] = action.data.data;
      return tempState;
    }

    case 'MERGE_FILE_VERSION_DATA': {
      const tempState = { ...state };
      const val = action.data;
      if (!Array.isArray(tempState[val.fileId])) {
        tempState[val.fileId] = [];
      } else {
        tempState[val.fileId] = [...tempState[val.fileId]];
      }
      tempState[val.fileId].push(val);
      return tempState;
    }

    case 'SET_FILE_VERSION_STATUS':
      return state;
    default:
      return state;
  }
}

export function setFilesData(state = {}, action) {
  switch (action.type) {
    case 'SET_FILES_DATA': {
      return { ...state, ...action.data };
    }
    case 'RESET_FILES_DATA': {
      return state;
    }
    default:
      return state;
  }
}

export function setFileDependencies(state = {}, action) {
  switch (action.type) {
    case 'SET_FILE_DEPENDENCIES': {
      return {
        ...state,
        [action.data.fileVersionId]: [...action.data.data],
      };
    }
    default:
      return state;
  }
}

export function filesSharedUnderResources(state = {}, action) {
  switch (action.type) {
    case 'SET_RESOURCES_UNDER_WHICH_FILES_SHARED': {
      return {
        [action.fileId]: action.data,
      };
    }
    default:
      return state;
  }
}
