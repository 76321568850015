import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import css from './index.module.css';
import { useState } from 'react';

const NotificationCard = (props) => {
  const unreadNotificationsCount = useSelector((state) => state.unreadNotificationsCount);
    const navigate = useNavigate();

    const [hoveredNotification, setHoveredNotification] = useState(null);

    const handleMouseEnter = (notification) => {
      setHoveredNotification(notification.id);
    };

    const handleMouseLeave = () => {
      setHoveredNotification(null);
    };

  const handleRedirect = (redirectUrl) => {
    navigate(redirectUrl);
    props.closeNotificationModal();
  };

  const notificationClicked = (redirectUrl, notificationId) => {
    if (redirectUrl) {
      props.dismissNotification(notificationId);
      handleRedirect(redirectUrl);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.new} hidden={!props.notificationsList.length}>
        <strong className={css.newTitle}>{props.title}</strong>
      </div>

      <div className={css.msgContainer}>
        {props.notificationsList.map((notification) => (
          <div
            onClick={() => notificationClicked(notification.redirectUrl, notification.id)}
            onMouseEnter={() => handleMouseEnter(notification)}
            onMouseLeave={handleMouseLeave}
            className={`${css.newMessages} ${hoveredNotification === notification.id && notification.redirectUrl ? 'cursor-pointer' : hoveredNotification === notification.id && !notification.redirectUrl ? 'cursor-not-allowed' : ''}`}
            key={notification.id}
          >
            <div className={css.userMessage}>
              <p className={css.message}>{notification.text}</p>
              {props.title === 'New' && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    props.dismissNotification(notification.id);
                  }}
                  className={css.DismissIconCss}
                  title="Mark as read"
                >
                  &#10005;
                </span>
              )}
            </div>
            <p className={css.duration}>
              {' '}
              {notification.time}
              {' '}
            </p>
          </div>
        ))}
      </div>
      {props.title === 'New' && unreadNotificationsCount.count > 4 && (
        <div className={css.moreNotifications}>
          <Link to="/notifications">
            <strong className={css.newTitle} onClick={props.closeNotificationModal}>
              +
              {unreadNotificationsCount.count - 4}
              {' '}
              more notifications
            </strong>
          </Link>
        </div>
      )}
    </div>
  );
};

export default NotificationCard;
