import {
 useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
 useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { TiFlowChildren } from 'react-icons/ti';
import { RiExchangeBoxLine } from 'react-icons/ri';
import { ReactComponent as ScheduleIcon } from '../../assets/icons/ScheduleIcon.svg';
import { ReactComponent as EstimateIcon } from '../../assets/icons/EstimateIcon.svg';
import { ReactComponent as SpacesIcon } from '../../assets/icons/SpacesIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/SettingsIcon.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/TasksIcon.svg';
import { ReactComponent as TransmittalIcon } from '../../assets/icons/TransmittalIcon.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/HomeIcon.svg';
import { ReactComponent as IssuesIcon } from '../../assets/icons/IssuesIcon.svg';
import { ReactComponent as ClashIcon } from '../../assets/icons/ClashIcon.svg';
import { ReactComponent as RiskManagementIcon } from '../../assets/icons/RiskManagementIcon.svg';
import { ReactComponent as AssetRegisterIcon } from '../../assets/icons/AssetRegisterIcon.svg';
import { ReactComponent as TendersIcon } from '../../assets/icons/TendersIcon.svg';
import { ReactComponent as ProcurementIcon } from '../../assets/icons/ProcurementIcon.svg';
import { ReactComponent as filesIcon } from '../../assets/icons/svg/FilesIcon.svg';
import { ReactComponent as progressUpdates } from '../../assets/icons/svg/ProgressUpdates.svg';
import { ReactComponent as generalForms } from '../../assets/icons/svg/generalForms.svg';
import { ReactComponent as changeOrder } from '../../assets/icons/svg/changeOrder.svg';
import { ReactComponent as permitToWork } from '../../assets/icons/svg/PermitToWork.svg';
import { ReactComponent as payments } from '../../assets/icons/svg/Payments.svg';
import { ReactComponent as bills } from '../../assets/icons/svg/bills.svg';
import { ReactComponent as inspectionForm } from '../../assets/icons/svg/inspectionForm.svg';
import { ReactComponent as Mailbox } from '../../assets/icons/svg/Mailbox.svg';
import { ReactComponent as Review } from '../../assets/icons/svg/Review.svg';
import Tool from '../HoopsViewer/ToolbarViewer/Tool';
import IconComponent from '../../cubeComponent/icons';
import { LiaFileContractSolid } from 'react-icons/lia';
import { MdOutlineChangeCircle } from 'react-icons/md';
import {
  getProjectBillsPageUrl,
  getProjectChangeOrderPageUrl,
  getProjectContractPageUrl, getProjectDashboardPageUrl, getProjectEstimatesPageUrl, getProjectFilesPageUrl, getProjectGeneralClashPageUrl, getProjectGeneralFormsPageUrl, getProjectInspectionsPageUrl, getProjectIssuesPageUrl, getProjectMailboxPageUrl, getProjectPaymentsPageUrl, getProjectPermitToWorkPageUrl, getProjectReviewsPageUrl, getProjectSchedulesPageUrl, getProjectSettingsPageUrl, getProjectSiteUpdatesPageUrl, getProjectTasksPageUrl, getProjectTransmittalsPageUrl, getProjectUsersPageUrl, getProjectWorkspacesListPageUrl,
  modulesNameSynonymMapper,
} from '../../services/utils/helpers';
import css from './index.module.css';

const Sidebar = ({ userData }) => {
  const { layout, currentSelectedSidebarCell: activeCell } = useSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [isExpanded, setIsExpanded] = useState('');
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true);
  const [sideBarMidIcons, setSideBarMidIcons] = useState([]);
  const [sideBarUpperFixedIcons, setSideBarUpperFixedIcons] = useState([]);

  const midIconsDivRef = useRef();

  useEffect(() => {
    const sideBarState = localStorage.getItem('isSideBarExpanded');
    setIsExpanded(sideBarState);
  }, [sideBarMidIcons]);

  useEffect(() => {
    setSideBarMidIcons([
      {
        // TODO vaibhav to implement allow parameter for files from backend as implemented for other icons
        allow: layout.FILES?.ENABLE,
        path: getProjectFilesPageUrl(projectId),
        name: 'Files',
        svg: filesIcon,
        toolTip: 'Files',
      },
      {
        allow: layout.ISSUES?.ENABLE,
        path: getProjectIssuesPageUrl(projectId),
        name: 'Issues',
        svg: IssuesIcon,
        toolTip: 'Issues',
      },
      {
        allow: layout.TASKS?.ENABLE,
        path: getProjectTasksPageUrl(projectId),
        name: 'Tasks',
        svg: TasksIcon,
        toolTip: 'Tasks',
      },
      // {
      //   allow: layout.FILES?.ENABLE,
      //   path: '/changeOrderList',
      //   name: 'Change Order',
      //   svg: MdOutlineChangeCircle,
      //   toolTip: 'Change Order',
      // },
      {
        allow: layout.ESTIMATES?.ENABLE,
        path: getProjectEstimatesPageUrl(projectId),
        name: 'Estimates',
        svg: EstimateIcon,
        toolTip: 'Estimates',
      },
      {
        allow: layout.SCHEDULES?.ENABLE,
        path: getProjectSchedulesPageUrl(projectId),
        name: 'Schedules',
        svg: ScheduleIcon,
        toolTip: 'Schedules',
      },
      {
        allow: layout.CONTRACT?.ENABLE,
        historyPush: false,
        path: getProjectContractPageUrl(projectId),
        name: 'Contracts',
        svg: LiaFileContractSolid,
        toolTip: 'Contracts',
      },
      {
        allow: layout.WORKFLOW?.ENABLE,
        path: getProjectReviewsPageUrl(projectId),
        name: 'Reviews',
        svg: Review,
        toolTip: 'Reviews',
      },
      {
        allow: layout.INSPECTION?.ENABLE,
        path: getProjectInspectionsPageUrl(projectId),
        name: 'Inspections',
        svg: inspectionForm,
        toolTip: 'Inspection Forms',
      },
      {
        allow: layout.PROGRESS_UPDATES?.ENABLE,
        path: getProjectSiteUpdatesPageUrl(projectId),
        name: 'Progress Updates',
        svg: progressUpdates,
        toolTip: 'Progress updates',
      },
      {
        allow: layout.GENERAL_FORMS?.ENABLE,
        path: getProjectGeneralFormsPageUrl(projectId),
        name: 'General Forms',
        svg: generalForms,
        toolTip: 'General Forms',
      },
      {
        allow: layout.BILLS?.ENABLE,
        path: getProjectBillsPageUrl(projectId),
        name: 'Bills',
        svg: bills,
        toolTip: 'Bills',
      },
      {
        allow: layout.CHANGE_ORDER?.ENABLE,
        path: getProjectChangeOrderPageUrl(projectId),
        name: 'Change Orders',
        svg: changeOrder,
        toolTip: 'Change Order',
      },
      {
        allow: layout.PAYMENTS?.ENABLE,
        path: getProjectPaymentsPageUrl(projectId),
        name: 'Payments',
        svg: payments,
        toolTip: 'Payments',
      },
      {
        allow: layout.PERMIT_TO_WORK?.ENABLE,
        path: getProjectPermitToWorkPageUrl(projectId),
        name: 'Permit To Work',
        svg: permitToWork,
        toolTip: 'Permit To Work',
      },
      {
        allow: layout.RISK_MANAGEMENT?.ENABLE,
        path: '/riskManagement',
        name: 'Risk Management',
        svg: RiskManagementIcon,
        toolTip: 'Risk Management',
      },
      // {
      //   allow: layout.TENDER_LIST?.ENABLE,
      //   path: '/tendersList',
      //   name: 'Tenders',
      //   svg: TendersIcon,
      //   toolTip: 'Tenders',
      // },
      // {
      //   allow: layout.ASSET_REGISTER?.ENABLE,
      //   path: '/assetRegister',
      //   name: 'Asset Register',
      //   svg: AssetRegisterIcon,
      //   toolTip: 'Asset Register',
      // },
      // {
      //   allow: layout.PROCUREMENT?.ENABLE,
      //   path: '/procurement',
      //   name: 'Procurement',
      //   svg: ProcurementIcon,
      //   toolTip: 'Procurement',
      // },
      // {
      //   allow: layout.WORKFLOW?.ENABLE,
      //   path: '/workflow',
      //   name: 'Workflows',
      //   svg: TiFlowChildren,
      //   toolTip: 'Workflows',
      // },
      // {
      //   allow: layout.CHANGE_ORDER?.ENABLE,
      //   path: '/changeOrder',
      //   name: 'ChangeOrder',
      //   svg: RiExchangeBoxLine,
      //   toolTip: 'Change Order',
      // },
      {
        allow: layout.CLASH?.ENABLE,
        path: getProjectGeneralClashPageUrl(projectId),
        name: 'Clash',
        svg: ClashIcon,
        toolTip: 'Clash',
      },
    ]);
    setSideBarUpperFixedIcons([
      {
        allow: true,
        path: getProjectDashboardPageUrl(projectId),
        name: 'Home',
        svg: HomeIcon,
        toolTip: 'Project Dashboard',
      },
      {
        allow: true,
        path: getProjectWorkspacesListPageUrl(projectId),
        name: window.location.host.includes('dpmsportal') ? `${modulesNameSynonymMapper.spaces}` : 'Spaces',
        svg: SpacesIcon,
        toolTip: window.location.host.includes('dpmsportal') ? `${modulesNameSynonymMapper.spaces}` : 'Spaces',
      },
      {
        allow: layout.TRANSMITTAL?.ENABLE,
        path: getProjectTransmittalsPageUrl(projectId),
        name: 'Transmittals',
        svg: TransmittalIcon,
        toolTip: 'Transmittals',
      },
      {
        allow: layout.MAILBOX?.ENABLE,
        path: getProjectMailboxPageUrl(projectId),
        name: 'Mailbox',
        svg: Mailbox,
        toolTip: 'Mailbox',
      },
    ]);
  }, [layout, projectId]);

  const handleScroll = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      setShowScrollUp(scrollableContent.scrollTop > 0);
      setShowScrollDown(
        scrollableContent.scrollHeight - scrollableContent.scrollTop !== scrollableContent.clientHeight,
      );
    }
  };

  const handleUpArrowClick = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      scrollableContent.scrollBy({ top: -100, behavior: 'smooth' });
    }
  };

  const handleDownArrowClick = () => {
    const scrollableContent = midIconsDivRef.current;
    if (scrollableContent) {
      scrollableContent.scrollBy({ top: 100, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleScroll();
  });

  const [showSettings, setShowSettings] = useState(true);

  const ActiveColor = 'rgb(107, 187, 227)';
  const InactiveColor = 'rgb(25, 32, 72)';

  useEffect(() => {
    if (layout.PROJECT_SETTINGS?.ENABLE === false) {
      setShowSettings(false);
    } else if (
      typeof layout.PROJECT_SETTINGS?.ENABLE === 'object'
      && layout.PROJECT_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME
    ) {
      setShowSettings(
        userData?.company?.name
        == layout.PROJECT_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME,
      );
    }
  }, [layout, userData]);

  const handleSideBarExpandClick = () => {
    if (isExpanded) {
      localStorage.setItem('isSideBarExpanded', '');
      setIsExpanded(false);
    } else {
      localStorage.setItem('isSideBarExpanded', 'yes');
      setIsExpanded(true);
    }
  };

  const handleRowClick = (path) => {
    navigate(path);
  };

  const RowTemplate = ({
 path, svg, toolTip, name, disabled = false,
}) => (
      <div
        onClick={() => handleRowClick(path)}
        className={`relative px-6 flex flex-row gap-4 items-center cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-auto'}`}
      >
        {activeCell === name ? (<div className={`${css.activeIndicator} absolute left-0`} />) : null}
        <Tool Svg={svg} Tooltip={toolTip} color={activeCell === name ? ActiveColor : InactiveColor} />
        <div
          className={`${css.descriptionClass} ${activeCell === name ? css.activeClass : ''
            }`}
          style={isExpanded ? null : { display: 'none' }}
        >
          {name}
        </div>
      </div>
    );

  const upperFixedIcon = useCallback(() => (
    <div className="gap-8 flex flex-col" data-testid="upperFixedIcons">
      {sideBarUpperFixedIcons.map(
        ({
 path, svg, toolTip, name, allow,
}) => allow && <RowTemplate key={name} path={path} svg={svg} toolTip={toolTip} name={name} />,
      )}
      <div className={css.line} />
    </div>
  ), [sideBarUpperFixedIcons, activeCell, isExpanded]);

  const middleIcons = useCallback(() => (
    <div className="py-4 gap-4 flex flex-col w-full h-full overflow-hidden" data-testid="middleIcons">
      <div onClick={handleUpArrowClick} id="upArrow" className={`${css.upArrowClass} ${showScrollUp ? 'visible' : 'invisible'}`}>
        <IconComponent name="BoldUpArrow" />
      </div>
      <div onScroll={handleScroll} ref={midIconsDivRef} className="gap-8 flex flex-col overflow-hidden">
        {sideBarMidIcons.map(
          ({
 path, svg, toolTip, name, allow,
}) => allow && <RowTemplate key={name} path={path} svg={svg} toolTip={toolTip} name={name} />,
        )}
      </div>
      <div onClick={handleDownArrowClick} className={`${css.downArrowClass} ${showScrollDown ? 'visible' : 'invisible'}`}>
        <IconComponent name="BoldDownArrow" />
      </div>
    </div>
  ), [sideBarMidIcons, activeCell, isExpanded, showScrollUp, showScrollDown]);

  const lowerFixedIcons = () => (
    <div className="flex flex-col gap-4" data-testid="lowerFixedIcons">
      <div className={css.line} />
      <RowTemplate
        key="sidebar_Settings"
        disabled={!showSettings}
        path={getProjectSettingsPageUrl(projectId)}
        svg={SettingsIcon}
        toolTip="Project Settings"
        name="Settings"
      />
    </div>
  );

  return (
    <div
      className={`${css.sidebar} ${localStorage.getItem('isSideBarExpanded')
        ? css.expanded
        : css.collapsed
        }`}
    >
      <div className="py-6 w-full flex flex-col">
        {upperFixedIcon()}
        {middleIcons()}
        {lowerFixedIcons()}
        <div
          onClick={handleSideBarExpandClick}
          className={
            isExpanded
              ? `${css.midHorizontalLineCollapsed}`
              : `${css.midHorizontalLineExpanded}`
          }
        />
      </div>
    </div>
  );
};

export default Sidebar;
