import { formCategoryObject } from '../../components/PortalSetings/Configuration/FormsAndFields/constants';
import { validImageTypes } from '../../cubeComponent/constants/globalVariable';
import _ from 'lodash';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getProjectWorkspacesListPageUrl = (projectId: string) => `/project/${projectId}/Workspaces?projectId=${projectId}`
export const getProjectWorkspacePageUrl = (
  projectId: string,
  spaceId: string,
  spaceType: string = "this",
  workflowType?: string
) => {
  let url = `/workspace/${spaceId}/explorer?spaceType=${spaceType}&projectId=${projectId}`;

  if (workflowType) {
    url += `&type=${workflowType}`;
  }
  
  return url;
};
export const getProjectIssuesPageUrl = (projectId: string) => `/project/${projectId}/issues?projectId=${projectId}`
export const getProjectFilesPageUrl = (projectId: string) => `/project/${projectId}/listFiles?projectId=${projectId}`
export const getProjectTransmittalsPageUrl = (projectId: string) => `/project/${projectId}/listTransmittals?projectId=${projectId}`
export const getProjectTasksPageUrl = (projectId: string, taskId?: string) => {
  if(taskId){
    return `/project/${projectId}/listTask/${taskId}?projectId=${projectId}`
  }
  return `/project/${projectId}/listTask?projectId=${projectId}`
}
export const getProjectEstimatesPageUrl = (projectId: string) => `/project/${projectId}/EstimateList?projectId=${projectId}`
export const getEstimatePageUrl = (projectId: string, estimateId: string) => `/project/${projectId}/estimate/${estimateId}?projectId=${projectId}`
export const getProjectUsersPageUrl = (projectId: string) => `/project/${projectId}/setting?projectId=${projectId}`
export const getProjectSettingsPageUrl = (projectId: string) => `/project/${projectId}/setting?projectId=${projectId}`
export const getProjectSchedulesPageUrl = (projectId: string) => `/project/${projectId}/scheduleList?projectId=${projectId}`
export const getProjectInspectionsPageUrl = (projectId: string) => `/project/${projectId}/inspectionFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectGeneralFormsPageUrl = (projectId: string) => `/project/${projectId}/generalFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectBillsPageUrl = (projectId: string) => `/project/${projectId}/billsFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectChangeOrderPageUrl = (projectId: string) => `/project/${projectId}/changeOrderFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectSiteUpdatesPageUrl = (projectId: string) => `/project/${projectId}/progressUpdatesFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectPaymentsPageUrl = (projectId: string) => `/project/${projectId}/paymentsFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectPermitToWorkPageUrl = (projectId: string) => `/project/${projectId}/permitToWorkFormsList?selectedTab=list&projectId=${projectId}`
export const getProjectDashboardPageUrl = (projectId: string) => `/project/${projectId}/projectdashboard?projectId=${projectId}`
export const getProjectMailboxPageUrl = (projectId: string) => `/project/${projectId}/mails?projectId=${projectId}`
export const getProjectReviewsPageUrl = (projectId: string) => `/reviews?projectId=${projectId}`
export const getProjectMailThreadsPageUrl = (projectId: string, mailId: string) => `/project/${projectId}/mails/${mailId}/mailThread?projectId=${projectId}`
export const getProjectContractPageUrl = (projectId: string) => `/project/${projectId}/contractsList?projectId=${projectId}`
export const getReviewUrl = (projectId: string, spaceId: string, reviewId: string) => `/review?reviewId=${reviewId}&spaceId=${spaceId}&projectId=${projectId}`
export const getPortalFilesUrl = () => `/Files?byPortal=true`
export const getProjectGeneralClashPageUrl = (projectId: string) => `/project/${projectId}/clashTests?projectId=${projectId}`
export const getClashDetailsPageUrl = (projectId: string, clashId: string) => `/project/${projectId}/clashDetails/${clashId}?projectId=${projectId}`
export const getCreateTransmittalPageUrl = (projectId: string, selectedSpaceId?: string) => {
  if(selectedSpaceId){
    return `/project/${projectId}/createTransmittals?selectedSpaceId=${selectedSpaceId}&projectId=${projectId}`
  }
  return `/project/${projectId}/createTransmittals?projectId=${projectId}`
}
export const getCreateTaskPageUrl = (projectId: string, extraSearchParams?: string) => {
  if(extraSearchParams){
    return `/project/${projectId}/createTasks?projectId=${projectId}&${extraSearchParams}`
  }
  return `/project/${projectId}/createTasks?projectId=${projectId}`
}
export const getEditTaskPageUrl = (projectId: string, taskId: string, extraSearchParams?: string) => {
  if(extraSearchParams){
    return `/project/${projectId}/editTask/${taskId}?projectId=${projectId}&${extraSearchParams}`
  }
  return `/project/${projectId}/editTask/${taskId}?projectId=${projectId}`
}

export const getViewerUrl = ({
  fileVersionId, transmittalVersionId, issueId, estimateId, measurementId, versionId, spaceId, scheduleId, projectId, contractId, preloadAndOpen, shortcutParentId, clashDetectionId
}) => {
  if(!projectId){
    return
  }
  const location = `/designviewer/file/${fileVersionId}`;
  const searchParams = new URLSearchParams('');

  if (scheduleId) {
    searchParams.set('scheduleId', scheduleId);
  }
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (spaceId) {
    searchParams.set('spaceId', spaceId);
  }
  if (contractId) {
    searchParams.set('contractId', contractId);
  }
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (transmittalVersionId) {
    searchParams.set('transmittalId', transmittalVersionId);
  }

  if(shortcutParentId) {
    searchParams.set('shortcutParentId', shortcutParentId);
  }
  if (issueId) {
    searchParams.set('issueId', issueId);
  }

  if (estimateId) {
    searchParams.set('estimateId', estimateId);
    if (measurementId) {
      searchParams.set('measurementId', measurementId);
    }
    if (versionId) {
      searchParams.set('versionId', versionId);
    }
  }

  if(clashDetectionId){
    searchParams.set('clashDetectionId', clashDetectionId);
  }

  return `${location}?${searchParams.toString()}${preloadAndOpen ? '&mode=scs':''}`;
};

export const getEstimateUrl = ({ estimateList, projectId, estimateId, versionId }) => {
  const searchParams = new URLSearchParams('')
  let location = `/project/${projectId}`
  if (estimateList) {
    location += '/EstimateList'
  }
  if (estimateId) {
    location +=`/estimate/${estimateId}`

    if (versionId) {
    location +=`/version/${versionId}`
    }
  }

  searchParams.set('projectId', projectId)

  return `${location}?${searchParams.toString()}`
}

export const getScheduleUrl = ({ projectId, scheduleId, selectedTab }) => {
  const searchParams = new URLSearchParams('');
  const location = `/project/${projectId}/schedule/${scheduleId}/scheduleDetails`;

  searchParams.set('selectedTab', selectedTab);
  searchParams.set('projectId', projectId);

  return `${location}?${searchParams.toString()}`;
};

export const getInspectionUrl = ({
  projectId, taskId, scheduleId, resourceId,selectedTab,resourceType
}) => {

  const url = `/project/${projectId}/forms/${resourceId}`
  const searchParams = new URLSearchParams('');
  if (taskId) {
    searchParams.set('taskId', taskId);
  }
  if (scheduleId) {
    searchParams.set('scheduleId', scheduleId);
  }
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  // if (resourceId) {
  //   searchParams.set('resourceId', resourceId);
  // }

  return `${url}?${searchParams.toString()}`;
};
export const getContractListUrl = (url, {
  projectId,selectedTab
}) => {
  const searchParams = new URLSearchParams('');
 
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }

  return `${url}?${searchParams.toString()}`;
};
export const getContractDetailUrl=({selectedTab,projectId,contractId})=>{

  const url = `/project/${projectId}/contract/${contractId}`
  const searchParams = new URLSearchParams('');
  
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }

  return `${url}?${searchParams.toString()}`;
}

export const getProjectFormTemplateUrl=({location,selectedCategory,projectId})=>{

  const searchParams = new URLSearchParams('');
  
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedCategory) {
    searchParams.set('selectedCategory', selectedCategory);
  }

  return `${location}?${searchParams.toString()}`;
}

export const getFormsListUrl=({selectedTab,projectId,resourceType})=>{

  const url = `/project/${projectId}/${formCategoryObject[resourceType].listUrlKey}`
  const searchParams = new URLSearchParams('');
  
  if (projectId) {
    searchParams.set('projectId', projectId);
  }
  if (selectedTab) {
    searchParams.set('selectedTab', selectedTab);
  }

  return `${url}?${searchParams.toString()}`;
}
export const getFormsTemplateUrl=({templateId,resourceType})=>{
  return `/portalSettings/formsAndFields/${templateId}/${resourceType.toLowerCase()}`
}

const chr = (codePt) => {
  if (codePt > 0xffff) {
    codePt -= 0x10000;
    return String.fromCharCode(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff));
  }
  return String.fromCharCode(codePt);
};

export const charToNum = (alpha) => {
  alpha = String(alpha).toUpperCase();
  let index = 0;
  for (let i = 0; ; i++) {
    if (alpha == numToChar(i)) {
      index = i;
     break;
    }
  }
  return index;
};

export const numToChar = (number) => {
  const numeric = (number - 1) % 26;
  const letter = chr(65 + numeric);
  const number2 = parseInt((number - 1) / 26);
  if (number2 > 0) {
    return numToChar(number2) + letter;
  }
  return letter;
};

export const toRoman = (number) => {
  let num = Math.floor(number);
  let val;
  let s = '';
  let i = 0;
  const v = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const r = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];

  function toBigRoman(n) {
    let ret = '';
    let n1 = '';
    let rem = n;
    while (rem > 1000) {
      let prefix = '';
      let suffix = '';
      let n = rem;
      const s = `${rem}`;
      let magnitude = 1;
      while (n > 1000) {
        n /= 1000;
        magnitude *= 1000;
        prefix += '(';
        suffix += ')';
      }
      n1 = Math.floor(n);
      rem = s - n1 * magnitude;
      ret += prefix + n1.toRoman() + suffix;
    }
    return ret + rem.toRoman();
  }

  if (number - num || num < 1) num = 0;
  if (num > 3999) return toBigRoman(num);

  while (num) {
    val = v[i];
    while (num >= val) {
      num -= val;
      s += r[i];
    }
    ++i;
  }
  return s;
};

export const fromRoman = (roman, accept) => {
  const s = roman.toUpperCase().replace(/ +/g, '');
  const L = s.length;
  let sum = 0;
  let i = 0;
  let next;
  let val;
  const R = {
    M: 1000, D: 500, C: 100, L: 50, X: 10, V: 5, I: 1,
  };

  function fromBigRoman(rn) {
    let n = 0;
    let x;
    let n1;
    let S;
    const rx = /(\(*)([MDCLXVI]+)/g;

    while ((S = rx.exec(rn)) != null) {
      x = S[1].length;
      n1 = Number.fromRoman(S[2]);
      if (isNaN(n1)) return NaN;
      if (x) n1 *= Math.pow(1000, x);
      n += n1;
    }
    return n;
  }

  if (/^[MDCLXVI)(]+$/.test(s)) {
    if (s.indexOf('(') == 0) return fromBigRoman(s);

    while (i < L) {
      val = R[s.charAt(i++)];
      next = R[s.charAt(i)] || 0;
      if (next - val > 0) val *= -1;
      sum += val;
    }
    if (accept || toRoman(sum) === s) return sum;
  }
  return NaN;
};

export const prepareEDN = (projectCustomId, indexAtProjectLevel) => `${projectCustomId}-${indexAtProjectLevel}`;

export const validateAvatar = (e, stateData) => {
  const file = e?.target?.files;
  const fileType = file[0].type;
  const data = stateData;
  if (validImageTypes.includes(fileType) && file && file.length > 0) {
    data.logoObj = file[0];
    data.error.logo = '';
  } else {
    data.error.logo = 'Please select jpg, jpeg, png file only.';
  }
  return data;
};

export const getTargetValue = (e) => e.value;

export const getSelectedIndex = (list, compareTo) => {
  let indexReturn = -1;
  list?.map((s, index) => {
    if (s.value === compareTo) {
      indexReturn = index;
    }
  });
  return indexReturn;
};

export function getLocationCoordinates() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const locationCoordinates = [position.coords.latitude, position.coords.longitude];
      resolve(locationCoordinates);
    }, (error) => {
      resolve([0]);
    });
  });
}

export function loadRecaptchaScripts() {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`;
  script.async = false;
  document.body.appendChild(script);
}

export const getTreeViewOfDistinctFileProperties = (data, eachColumnMetadata = {}, selectedNodes = [], appendToLeafNodes = {}, appendToNonLeafNodes = {}) => {
  const columnData = [];

  data.forEach((name) => {
    const rootNode = name.split('.')[0];
    const nameArray = name.split('.').slice(1).join('.').split('/');

    let rootNodeIndex = columnData.findIndex((data) => data.field == rootNode);

    if (rootNodeIndex === -1) {
      let label = 'Metadata';
      if (rootNode == 'customProperties') {
        label = 'Custom Metadata';
      }
      else if (rootNode == 'typeProperties') {
        label = 'Type Metadata';
      }
      const index = columnData.push({
        field: rootNode,
        label,
        columns: [],
        ...appendToNonLeafNodes,
        ...eachColumnMetadata,
      });
      rootNodeIndex = index - 1;
    }

    const parentNodeIndex = columnData[rootNodeIndex].columns.findIndex((data) => data.label == nameArray[0]);

    if (parentNodeIndex !== -1) {
      columnData[rootNodeIndex].columns[parentNodeIndex].columns.push({
        field: name,
        label: nameArray.slice(1).join('/'),
        isChecked: !!selectedNodes.includes(name),
        ...appendToLeafNodes,
        ...eachColumnMetadata,
      });
    }
    else {
      const fieldName = `${rootNode}.${nameArray[0]}`;
      let data = {
        field: fieldName,
        label: nameArray[0],
        isChecked: !!selectedNodes.includes(fieldName),
        ...appendToLeafNodes,
        ...eachColumnMetadata,
      };
      if (nameArray.length > 1) {
        data = {
          ...data,
          ...appendToNonLeafNodes,
          columns: [{
            field: name,
            label: nameArray.slice(1).join('/'),
            isChecked: !!selectedNodes.includes(name),
            ...appendToLeafNodes,
            ...eachColumnMetadata,
          }],
        };
      }
      columnData[rootNodeIndex].columns.push(data);
    }
  });

  return columnData;
};

export function removingElementFromRcDock(child, nonActiveIds) {
  if (child.children?.length > 0) {
    child.children = child.children.filter((child) => removingElementFromRcDock(child, nonActiveIds));
  }
  if (child.tabs) {
    if (child.tabs.length > 1) {
      child.tabs = child.tabs.filter((tab) => !nonActiveIds.includes(tab.id));
      if (nonActiveIds.includes(child.activeId)) {
        if (!child.tabs[0]?.id) {
          return false;
        }
        child.activeId = child.tabs[0].id;
      }
      return true;
    }
    if (nonActiveIds.includes(child.activeId)) {
      return false;
    }
    return true;
  }
  return true;
}

export const getFileListPageUrl = (searchParams: string) => {
  const url = new URLSearchParams();
  if (searchParams) {
    url.set('search', searchParams)
  }
  return `/Files?byPortal=true&${url.toString()}`;
}

export const getProjectPageUrl = (searchParams : string, typeProject:string, viewProject: string) => {
  const url = new URLSearchParams();
  if (searchParams) {
    url.set('search', searchParams)
  }
  url.set('type', typeProject)
  url.set('view',viewProject)
  return `/projects?${url.toString()}`;
};


const RESOURCE_TYPE_PROJECT = 'RESOURCE_TYPE_PROJECT';
const RESOURCE_TYPE_PORTAL = 'RESOURCE_TYPE_PORTAL';
const RESOURCE_TYPE_TRANSMITTAL = 'RESOURCE_TYPE_TRANSMITTAL';
const RESOURCE_TYPE_SPACE = 'RESOURCE_TYPE_SPACE';

export const getPermissions = () => ({
  ACCEPT_COMPANIES: {
    description: 'Accept Company',
    id: 41,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  ACCESS_PORTAL_SETTINGS: {
    description: 'Access Portal Settings',
    id: 63,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  ACCESS_PROJECTS_PART_OF: {
    description: 'Access projects that they are part of',
    id: 64,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  ASSIGN_PROJECT_TO_REGION: {
    description: 'Assign projects to a region',
    id: 44,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  CHANGE_PORTAL_INFORMATION: {
    description: 'Change Portal information',
    id: 65,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  CONFIGURATION_SETTINGS_MODIFY: {
    description: 'Modify Configuration Settings',
    id: 18,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  CONFIGURATION_SETTINGS_VIEW: {
    description: 'View Configuration Settings',
    id: 17,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  CREATE_PORTAL_ROLES: {
    description: 'Create Portal Role',
    id: 50,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  CREATE_REGION: {
    description: 'Create regions',
    id: 47,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  DELETE_PORTAL_ROLES: {
    description: 'Delete Portal Role',
    id: 51,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  DELETE_REGION: {
    description: 'Delete regions',
    id: 46,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  EDIT_INFORMATION_OF_COMPANIES: {
    description: 'Edit Company information',
    id: 43,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  EDIT_PORTAL_ROLES: {
    description: 'Edit Portal Roles',
    id: 53,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  EDIT_PROJECT_INFO: {
    description: 'Edit project information',
    id: 5,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  EDIT_REGION: {
    description: 'Edit regions',
    id: 48,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  FOLDER_ROOT_PERMISSION_CHANGE: {
    description: 'Change Root folder permissions of user',
    id: 11,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  PORTAL_ADD_COMPANIES: {
    description: 'Add company to the portal',
    id: 40,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  PORTAL_REMOVE_COMPANIES: {
    description: 'Remove company from the portal',
    id: 39,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  PROJECT_ADD_TO_PORTAL: {
    description: 'Add projects to the portal',
    id: 33,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  PROJECT_ARCHIVE_OF_PORTAL: {
    description: 'Archive projects of the portal',
    id: 35,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  PROJECT_INFORMATION_EDIT: {
    description: 'Edit Project Information',
    id: 36,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  PROJECT_LIST_VIEW_IN_PORTAL: {
    description: 'View all Projects List of Portal',
    id: 34,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  REJECT_COMPANIES: {
    description: 'Reject Company',
    id: 42,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  REMOVE_PROJECT_FROM_REGION: {
    description: 'Remove projects from a region',
    id: 45,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  ROLES_ADD_TO_PROJECT: {
    description: 'Add Project Roles',
    id: 13,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  ROLES_CHANGE_OF_USER_IN_PROJECT: {
    description: 'Change Project role of a user',
    id: 15,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  ROLES_DELETE_IN_PROJECT: {
    description: 'Delete Project Roles',
    id: 16,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  ROLES_EDIT_IN_PROJECT: {
    description: 'Edit Project Roles',
    id: 14,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  ROLES_VIEW_IN_PROJECT: {
    description: 'View Project Roles',
    id: 12,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  SPACE_ADD_COMPANIES_TO_SPACE: {
    description: 'Add companies to space',
    id: 22,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_ADD_USERS_TO_SPACE: {
    description: 'Add Users to Space',
    id: 20,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_ASSIGN_SHARING_TO_FILE_FOLDER: {
    description: 'Assign sharing process to files/folder',
    id: 31,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_CHANGE_SPACE_INFORMATION: {
    description: 'Change Space Information',
    id: 19,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_COMMENT_ON_FILE: {
    description: 'Comment on a file',
    id: 28,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_CREATE_FILE_PLACEHOLDERS: {
    description: 'Create File Spaceholders',
    id: 27,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_CREATE_FOLDERS_SUBFOLDERS: {
    description: 'Create folders and sub folders',
    id: 24,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_DELETE_OTHER_COMMENTS: {
    description: 'Delete other comments',
    id: 30,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_DELETE_OWN_COMMENTS: {
    description: 'Delete own comments',
    id: 29,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_DOWNLOAD_FILES_FOLDERS: {
    description: 'Download Files/Folders',
    id: 26,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_REMOVE_COMPANIES_FROM_SPACE: {
    description: 'Remove companies from space',
    id: 23,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_REMOVE_USERS_FROM_SPACE: {
    description: 'Remove users from space',
    id: 21,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_UNASSIGN_SHARING_TO_FILE_FOLDER: {
    description: 'Unassign sharing process to files/folder',
    id: 32,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACE_UPLOAD_FILES: {
    description: 'Upload files',
    id: 25,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_SPACE,
  },
  SPACES_ADD_TO_PROJECT: {
    description: 'Add project spaces',
    id: 6,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  SPACES_DELETE_FROM_PROJECT: {
    description: 'Delete project spaces',
    id: 7,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  SPACES_EDIT: {
    description: 'Edit Project spaces',
    id: 8,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  SPACES_VIEW_IN_PROJECT: {
    description: 'View Project Spaces',
    id: 9,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  STATUS_CREATE_OF_PORTAL_ENTITIES: {
    description: 'Create Status of Portal Entities',
    id: 54,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  STATUS_DELETE_OF_PORTAL_ENTITIES: {
    description: 'Delete Status of Portal Entities',
    id: 55,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  STATUS_EDIT: {
    description: 'Edit Status',
    id: 56,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  STATUS_VIEW: {
    description: 'View Status',
    id: 57,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  TRANSMITTAL_CREATE: {
    description: 'Create Transmittal',
    id: 10,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  USERS_ADD_TO_PROJECT: {
    description: 'Add users to current project',
    id: 0,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  USERS_ADD_TO_SPACE: {
    description: 'Add User to a space',
    id: 2,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  USERS_ASSIGN_ROLE_TO_PROJECTS: {
    description: 'Assign users and their role to projects',
    id: 59,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  USERS_PORTAL_ROLE_CHANGE: {
    description: 'Change portal role of any user(s)',
    id: 61,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  USERS_PROJECT_ROLE_CHANGE: {
    description: 'Change project roles of users in any projects of that portal',
    id: 62,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  USERS_REMOVE_ACCESS_TO_PROJECTS: {
    description: 'Remove which users can access which projects',
    id: 60,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  USERS_REMOVE_FROM_PROJECT: {
    description: 'Remove users from a project',
    id: 1,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  USERS_REMOVE_FROM_SPACE: {
    description: 'Remove User from a Space',
    id: 3,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  USERS_VIEW_ALL_IN_PORTAL: {
    description: 'View all users of Portal',
    id: 58,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  USERS_VIEW_IN_PROJECT: {
    description: 'View Project Users',
    id: 4,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
  VIEW_ACCEPTED_COMPANIES: {
    description: 'View Accepted Companies',
    id: 37,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  VIEW_INVITED_COMPANIES: {
    description: 'View Invited Companies',
    id: 38,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  VIEW_LIST_OF_PORTAL_ROLES: {
    description: 'View Portal Roles',
    id: 52,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  VIEW_LIST_OF_REGION: {
    description: 'View regions',
    id: 49,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PORTAL,
  },
  SPECIFIC_CREATE_FORM_FLOW: {
    description: 'Create Form Flow',
    id: 66,
    status: 'ACTIVE',
    type: RESOURCE_TYPE_PROJECT,
  },
});

export function getFileImage(file: Object) {
  let fileExtension;
  if(_.get(file, 'name'))
  {
     fileExtension = _.get(file, 'name').split('.').pop().toLowerCase();
  }

  try {
    if (file.resourceType == 'RESOURCE_TYPE_SHORTCUT_FILE') {
      return require(`../../assets/icons/svg/filesShortcutIcons/Sfile${fileExtension.toUpperCase()}.svg`);
    }
    else if (file.resourceType == 'RESOURCE_TYPE_MERGED_FILE') {
      return require('../../assets/icons/svg/FileMER.svg').default;
    }
    else {
      return require(`../../assets/icons/svg/File${fileExtension.toUpperCase()}.svg`);
    }
  } catch (e) {
    return require('../../assets/icons/svg/files.svg').default;
  }
}

export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export const modulesNameSynonymMapper = {
  'projects': 'Sub-Divisions',
  'spaces': 'Schemes',
  'space': "Scheme",
  'spaceName': 'Scheme ID',
  'projectName': 'Sub-Division Name',
  'projectId': "Sr.No",
  'spaceDescription': "Scheme Name"
}